import React, { useCallback, useRef, useState } from "react";
import ReportViewer from "./ReportViewerComponent";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Icon from "components/Global/icon";
import styles from "./Reports.module.scss";
import { useEffect } from "react";
import { SectionDividerFull } from "components/Contact/sectionDivider";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { CustomDateInput } from "components/common/CustomDateInput";
import { baseURL } from "api/axios.config";

const BillingReport = () => {
  const DateFormat = (value) => {
    let date = new Date(value);
    return date.toISOString().split("T")[0];
  };

  const groupData = [
    {
      name: "None",
      value: "NULL",
    },
    {
      name: "Date",
      value: "Date",
    },
    {
      name: " Fee Earner",
      value: "FeeEarnerRef",
    },
    {
      name: "Matter Ref",
      value: "MatterRef",
    },
  ];

  const orderData = [
    {
      name: "Fee Earner",
      value: "FeeEarnerRef",
    },
    {
      name: "Date",
      value: "Date",
    },
    {
      name: "Reference",
      value: "Reference",
    },
    {
      name: "Matter Ref",
      value: "MatterRef",
    },
  ];

  let date = new Date();
  const [fromDate, setFromDate] = React.useState(date);

  const analyzeParameters = () => {
    setParameters({
      ExcludePaidBills: excludeZero,
      FromDate: includeFromDate ? DateFormat(fromDate) : undefined,
      ToDate: includeEndDate ? DateFormat(endDate) : undefined,
      OrderBy: orderbyType?.value,
      GroupBy: groupByType?.value,
      IncludeReversals: includeReversals,
    });
    setShouldLoad(true);
  };

  const [orderbyType, setOrderByType] = useState({
    value: "date",
    name: "Date",
  });

  const [groupByType, setGroupByType] = useState({
    name: "None",
    value: "NULL",
  });

  const [includeFromDate, setIncludeFromDate] = useState(false);
  const [includeEndDate, setIncludeEndDate] = useState(false);
  const [excludeZero, setExcludeZero] = useState(false);
  const [includeReversals, setIncludeReversals] = useState(false);
  const [show, setShow] = useState(window.innerWidth);
  const [shouldLoad, setShouldLoad] = useState(false);

  const onChangeFromDateSelect = (event) => {
    if (includeFromDate) {
      setFromDate(event.value);
    }
  };

  const [endDate, setEndDate] = React.useState(date);

  const [parameters, setParameters] = useState({
    ExcludePaidBills: excludeZero,
    FromDate: includeFromDate ? DateFormat(fromDate) : undefined,
    ToDate: includeEndDate ? DateFormat(endDate) : undefined,
    OrderBy: "Date",
    GroupBy: "NULL",
    IncludeReversals: includeReversals,
  });

  const onChangeEndSelect = (event) => {
    if (includeEndDate) {
      setEndDate(event.value);
    }
  };

  const UpdateButton = (
    <Button className="common-btn" onClick={analyzeParameters}>
      Update
    </Button>
  );

  const toDatePicker = (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox
        value={includeEndDate}
        className="ms-1 me-1 mt-auto mb-auto"
        onChange={(e) => {
          setIncludeEndDate(e.value);
        }}
        label={""}
      />
      <DatePicker
        id="to"
        className="date-picker"
        value={endDate || null}
        onChange={onChangeEndSelect}
        width={"200px"}
        dateInput={CustomDateInput}
        toggleButton={CalenderCustomToggleButton}
        defaultValue={endDate || null}
        placeholder="Select to date"
      />
    </div>
  );

  const fromDatePicker = (
    <div className="d-flex align-items-center justify-content-center">
      <Checkbox
        value={includeFromDate}
        className="ms-2 me-2 mt-auto mb-auto"
        onChange={(e) => {
          setIncludeFromDate(e.value);
        }}
        label={""}
      />
      <DatePicker
        id="to"
        className="date-picker"
        value={fromDate || null}
        onChange={onChangeFromDateSelect}
        dateInput={CustomDateInput}
        toggleButton={CalenderCustomToggleButton}
        defaultValue={fromDate || null}
        placeholder="Select to date"
      />
    </div>
  );

  const IncludeCheckbox = (
    <Checkbox
      className="checkbox-label"
      value={excludeZero}
      onChange={(e) => {
        setExcludeZero(e.value);
      }}
      style={{
        fontSize: "12px",
      }}
      label={"Exclude Paid Bills"}
    />
  );

  const IncludeReversals = (
    <Checkbox
      className="checkbox-label"
      value={includeReversals}
      onChange={(e) => {
        setIncludeReversals(e.value);
      }}
      style={{
        fontSize: "12px",
      }}
      label={"Include Reversals"}
    />
  );

  const orderByDropDown = (
    <div className="">
      <ComboBox
        className="ps-1"
        iconClassName="wa"
        icon={<Icon icon="arrow-down" />}
        data={orderData}
        required
        value={orderbyType}
        disabled={groupByType.value !== "NULL"}
        onChange={(e) => {
          setOrderByType(e.value);
        }}
        dataItemKey={"value"}
        textField={"name"}
        placeholder="Order by"
        style={{ maxWidth: "325px", height: "44px" }}
      />
    </div>
  );

  const groupByDropDown = (
    <div className="">
      <ComboBox
        className="ps-1"
        iconClassName="wa"
        icon={<Icon icon="arrow-down" />}
        data={groupData}
        required
        value={groupByType}
        onChange={(e) => {
          setGroupByType(e.value);
        }}
        dataItemKey={"value"}
        textField={"name"}
        placeholder="Group by"
        style={{ maxWidth: "325px", height: "44px" }}
      />
    </div>
  );

  const reportViewer = (
    <ReportViewer
      name="BillsDayBook.trdp"
      parameters={parameters}
      shouldLoad={shouldLoad}
      setShouldLoad={setShouldLoad}
      url={`${baseURL}/Accounts/Reporting/reports`}
    />
  );

  useEffect(() => {
    function handleResize() {
      if (show > 1400 && window.innerWidth < 1400) {
        setShow(window.innerWidth);
      } else if (show < 1400 && window.innerWidth > 1400) {
        setShow(window.innerWidth);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {show < 1400 ? (
        <div
          className={`container-fluid w-100 ps-4 pt-2 ${
            styles[`report-small-screen`]
          }`}
        >
          <div className="mt-3 d-flex flex-wrap align-items-center row">
            <div className="col-3">
              <div className="nominal-form-to-parent-row">
                <div className="labels-containers">
                  <div className="label-to">
                    <Label editorId="to" className="label">
                      To
                    </Label>
                  </div>
                </div>
              </div>
              <div className="nominal-form-to-row">
                <div className="date-form-to-wrapper">
                  <div className="to">{toDatePicker}</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column col-3">
              <div className="label-to">
                <Label className="label mb-1">Order By</Label>
              </div>
              {orderByDropDown}
            </div>
            <div className="pt-2 col-3">{IncludeCheckbox}</div>
            <div className="pt-2 col-3">{UpdateButton}</div>
          </div>
          <div className={`row mt-4 ${styles[`reportBackground`]} 1fr`}>
            {reportViewer}
          </div>
        </div>
      ) : (
        <div
          className={`container-fluid w-100 p-4 pt-2 mt-4 ${
            styles[`report-large-screen`]
          }`}
        >
          <div className="row h-100">
            <div className="col" style={{ maxWidth: "300px" }}>
              <div
                className={`p-4 pt-4 ${styles[`reportBackground`]} ${
                  styles[`height`]
                }`}
              >
                <p className={`${styles[`heading`]} fs-lg`}>Filters</p>
                <SectionDividerFull sectionName={""} />
                <div className="d-flex flex-column mt-2">
                  <Label className="label mb-1">From</Label>
                  <div className="no-borders">{fromDatePicker}</div>
                </div>
                <div className="d-flex flex-column mt-2">
                  <Label className="label mb-1">To</Label>
                  <div className="no-borders">{toDatePicker}</div>
                </div>
                <SectionDividerFull sectionName={""} />
                <div className="d-flex flex-column pt-1">
                  <Label className="label mb-1">Group By</Label>
                  {groupByDropDown}
                </div>
                <SectionDividerFull sectionName={""} />
                <div className="d-flex flex-column pt-1">
                  <Label className="label mb-1">Order By</Label>
                  {orderByDropDown}
                </div>
                <SectionDividerFull sectionName={""} />
                <div className="pt-3">{IncludeCheckbox}</div>
                <div className="pt-3">{IncludeReversals}</div>
                <SectionDividerFull sectionName={""} />
                <div className="pt-3 float-end">{UpdateButton}</div>
              </div>
            </div>
            <div className="col">
              <div className={`row ${styles[`reportBackground`]} 1fr`}>
                {reportViewer}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BillingReport;
