import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector, useDispatch } from "react-redux";
import { resetdata } from "actions/postings.action";
import styles from "./Posting.module.scss";
import image from "../../assets/success.png";

const Modalposting = (props) => {
  const [visible, setVisible] = useState(false);
  const [response, setresponse] = useState(false);
  const [statemanager, setstatemanager] = useState(false);

  const result = useSelector((state) => {
    return state.postingsdocs;
  });
  const dispatch = useDispatch();
  const toggleDialog = () => {
    if (
      response?.transactionNo !== undefined &&
      response?.transactionNo !== "Loading"
    ) {
      props.reset();
    }
    dispatch(resetdata());
    props.closeModal();
  };

  useEffect(() => {
    setTimeout(() => {
      setstatemanager(!statemanager);
      if (result.IsError === true) {
        setresponse({ errorData: result?.errorData?.response?.data?.message });
      } else if (result.data !== undefined && result !== null) {
        setresponse(result?.data?.data[0] || result?.data?.data?.data[0]);
      }

      setVisible(props?.value);
    });
  }, [props.value, result]);

  return (
    <div>
      {visible && (
        <Dialog
          // title={"Please confirm"}
          onClose={toggleDialog}
        >
          <div className={`${styles[`postingDialog`]} d-flex `}>
            {response?.transactionNo === "Loading" ? (
              <div className="align-items-center m-auto">
                <p>Loading...</p>
              </div>
            ) : response?.transactionNo !== undefined &&
              response.transactionNo !== "Loading" ? (
              <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
                <img src={image} className="mx-auto mt-2 mb-2"></img>
                <div className={`mx-auto mt-4 mb-3 ${styles[`modal-text`]}`}>
                  {props.component === "ChitRequest" ? (
                    <p>Chit Id : {response.transactionNo}</p>
                  ) : (
                    <p>Transaction No : {response.transactionNo}</p>
                  )}
                  <p>Posted Successfully - {response.postingType}</p>
                </div>
                <button className={`common-btn mt-3`} onClick={toggleDialog}>
                  Done
                </button>
              </div>
            ) : (
              <>
                <div className="justifiy-content-center d-flex flex-column align-items-center vw-100">
                  <h3 className="primary mt-3 text-danger">Error!</h3>
                  <p className="mt-3">{response?.errorData}</p>
                  <button className={`common-btn mt-3`} onClick={toggleDialog}>
                    Done
                  </button>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Modalposting;
