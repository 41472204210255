import { API } from "./axios.config";

export const getBankRecNominals = () =>
  API.get(`/accounts/nominals?banks=true&Orderby=subType,nominalRef`);

export const addNominal = (data) =>
  API.post(`/accounts/nominals`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getOfficeBankNominals = () =>
  API.get(`/accounts/nominals?subtype=BalanceSheet_OfficeBank&archived=false`);

export const getclientList = () =>
  API.get(`/accounts/nominals?subtype=BalanceSheet_ClientBank&archived=false`);

export const getddaList = () =>
  API.get(`/accounts/nominals?subtype=BalanceSheet_DDABank&archived=false`);

export const getViewNominals = (id) => API.get(`/accounts/nominals/${id}`);

export const updateNominal = (id, data) =>
  API.patch(`/accounts/nominals/${id}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const searchNominal = (page, limit, search) =>
  API.get(`/accounts/nominals?search=${search}&page=${page}&limit=${limit}`);

export const getNominalBalances = (nominalRef, FromDate, ToDate) =>
  API.get(
    `/accounts/nominals/Ref/${nominalRef}/Balances?FromDate=${FromDate}&ToDate=${ToDate}`
  );

export const getNominalsByRef = (nominalRef) =>
  API.get(`/accounts/nominals/Ref/${nominalRef}`);

export const getactivenominals = () =>
  API.get(`/accounts/nominals?archived=false&active=true`);

export const getActiveIncomeNominals = () =>
  API.get(`/accounts/nominals?banks=false&Orderby=subType,nominalRef`);

export const getBankTransferNominals = () =>
  API.get(
    `/accounts/nominals?subType=BalanceSheet_OfficeBank&active=true&Orderby=subType,nominalRef`
  );

export const getIncomeNominals = () =>
  API.get(
    "/accounts/nominals?archived=false&active=true&subtype=ProfitAndLoss_Income"
  );

export const getDDABankNominals = () =>
  API.get(
    "/accounts/nominals?archived=false&active=true&subtype=BalanceSheet_DDABank"
  );

export const getNominalsList = (parameter) => {
  let url = `/accounts/nominals?`;
  if (parameter !== "") {
    url += `${parameter}`;
  }
  return API.get(url);
};

export const getNominalJournalPosting = () =>
  API.get(`/accounts/nominals?archived=false&controlNominal=false`);

export const getNominalsBySubtype = (
  subtype,
  active = true,
  archived = false
) => {
  return API.get(
    `accounts/nominals?subtype=${subtype}&active=${active}&archived=${archived}`
  );
};

export const editPosting = (transactionNo, data) =>
  API.patch(`/accounts/postings/${transactionNo}`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const reversePosting = (transactionNo, data) =>
  API.request({
    url: `/accounts/postings/${transactionNo}`,
    method: "DELETE",
    data: JSON.stringify({ data }),
    headers: { "Content-Type": "application/json" },
  });

export const getTransactionPeriod = (page, limit) =>
  API.get(`/Accounts/TransactionPeriods`);

export const getCurrentSessionTransactionPeriod = (sessionPeriod) =>
  API.get(
    `/Accounts/TransactionPeriods/Current?sessionPeriod=${sessionPeriod}`
  );

export const getCurrentTransactionPeriod = () =>
  API.get(`/Accounts/TransactionPeriods/Current`);

export const updateTransactionPeriod = (data) =>
  API.post(`/Accounts/TransactionPeriods`, JSON.stringify({ data }), {
    headers: { "Content-Type": "application/json" },
  });

export const getDefaultNominals = (branchId) =>
  API.get(`/Accounts/DefaultNominal/${branchId}`);

export const getNewDefaultNominals = (branchId) =>
  API.get(`/Accounts/DefaultNominal/${branchId}/New`);

export const updateDefaultNominals = (branchId, data) =>
  API.post(`/Accounts/DefaultNominal/${branchId}`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
