import styles from "./CaseWorkflow.module.scss";
import { TreeView } from "@progress/kendo-react-treeview";
import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Breadcrumb } from "@progress/kendo-react-layout";
import {
  getCaseWorkFlows,
  getCaseWorkFlowsItem,
  updateWorkFlow,
  addCaseWorkflowRecord,
  getAllWorkFlowInterviews,
  runDocumentTemplate,
} from "api";
import { toast } from "react-toastify";
import DocumentIcon from "../../../assets/fileIcons/Document.svg";
import MultiDocumentsIcon from "../../../assets/fileIcons/MultiDocuments.svg";
import UserScreenIcon from "../../../assets/CaseFileIcons/UserScreen.svg";
import MultiScreenIcon from "../../../assets/CaseFileIcons/MultiScreens.svg";
import UserScreenDocumentIcon from "../../../assets/CaseFileIcons/UserScreenDocument.svg";

import Interview from "../../../assets/CaseFileIcons/ClientInterview.svg";
import FolderIcon from "../../../assets/fileIcons/folder.svg";
import { DateFormatCell } from "components/common/formatter";
import { Splitter } from "@progress/kendo-react-layout";
import { SvgIcon } from "@progress/kendo-react-common";
import { homeIcon } from "@progress/kendo-svg-icons";
import { AiOutlineRightCircle } from "react-icons/ai";
import { Button } from "@progress/kendo-react-buttons";
import { DragAndDrop } from "@progress/kendo-react-common";
import { MyCommandCell } from "./MyCommandCell";
import UserScreen from "./UserScreen";
import DropdownCell from "./DropDownCell";
import DraggableRow from "./DraggableRow";
import DragHandleCell from "./DragHandleCell";
import ScreenDesigner from "./ScreenDesigner/ScreenDesigner";
import AddFolder from "components/CaseFiles/AddFolderDialog";
import WorkFlowDialog from "components/CaseFiles/AddWorkFlowDialog";
import ClientInterviewDialog from "components/CaseFiles/ClientInterviewDialog";
import DocTemplateRunner from "components/CaseFiles/DocTemplateRunner";
import CaseActivityForm from "../CaseActivities/CaseActivityForm";

export const ReorderContext = React.createContext({
  reorder: () => {},
  dragStart: () => {},
});

const editField = "inEdit";

const CommandCell = (props) => {
  const { add, edit, update, cancel, discard, editField } = props;
  return (
    <MyCommandCell
      {...props}
      add={add}
      edit={edit}
      update={update}
      cancel={cancel}
      discard={discard}
      editField={editField}
    />
  );
};

export default function CaseWorkFlows({
  reference,
  editWorkflow,
  matterId,
  ...props
}) {
  const [folders, setFolders] = useState([]);
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [addWorkflow, setAddWorkFlow] = useState(false);
  const [addFolder, setAddFolder] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [interviewId, setInterviewId] = useState(null);
  const [droppedFilesActivity, setDroppedFilesActivity] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [parentId, setParentId] = useState(null);
  const [showDocTemplate, setShowDocTemplate] = useState(false);
  const [showClientInterview, setShowClientInterview] = useState(false);
  const [showScreenDesigner, setShowScreenDesigner] = useState(false);
  const [editItem, setEditItem] = useState(undefined);
  const [refreshData, setRefreshData] = useState(false);
  const [clientWFID, setClientWFID] = useState(null);
  const [workflowItems, setWorkflowItems] = useState([]); // To store the list of workflow items
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [workflowInterviews, setWorkflowInterviews] = useState([]);
  const [panes, setPanes] = useState([
    {
      size: "22%",
      min: "15%",
      max: "60%",
      collapsible: false,
    },
    {},
  ]);

  const [activeItem, setActiveItem] = useState(null);
  const reorder = (dataItem, direction) => {
    if (activeItem === dataItem) {
      return;
    }
    let reorderedData = data.slice();
    let prevIndex = reorderedData.findIndex((p) => p === activeItem);
    let nextIndex =
      reorderedData.findIndex((p) => p === dataItem) +
      (direction === "before" ? -1 : 0);
    if (prevIndex > nextIndex) {
      nextIndex++;
    }
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem || reorderedData[0]);
    setData(reorderedData);
  };
  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  useEffect(() => {
    if (props?.refreshClicked && props.activeItem === 3) {
      setRefreshData((prevState) => !prevState);
      props?.setRefreshClicked(false);
    }
  }, [props.refreshClicked]);

  useEffect(() => {
    if (!selected) {
      setBreadcrumb([homeBreadcrumb]);
    }
    if (parentId) {
      getCaseWorkFlowItems(parentId);
      return;
    }
    getWorkFlowFolders();
  }, [refreshData]);

  useEffect(() => {
    if (!editWorkflow) {
      const newData = data.map((item) => ({ ...item, inEdit: false }));
      setData(newData);
    }
  }, [editWorkflow]);

  useEffect(() => {
    const fetchWorkflows = async () => {
      const response = await getAllWorkFlowInterviews();
      setWorkflowInterviews(response.data.data);
    };

    fetchWorkflows();
  }, [showScreenDesigner]);

  const next = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;

      if (nextIndex < workflowItems.length) {
        // Process the next step based on updated index
        processStep(workflowItems[nextIndex]);
      } else {
        // resetWorkflow(); // Reset if all steps are completed
      }

      return nextIndex; // Update currentIndex to nextIndex
    });
  };

  // Function to reset the state when all steps are processed
  const resetWorkflow = () => {
    setWorkflowItems((prevItems) => []); // Clear workflow items
    setCurrentIndex(-1); // Reset index
  };

  const homeBreadcrumb = {
    id: "home",
    icon: <SvgIcon icon={homeIcon} />,
  };

  const TypeIconCell = (props) => {
    const types = props.dataItem.workflowItems
      ? props.dataItem.workflowItems.map((item) => item.itemType).join(", ")
      : "";

    let iconToRender;
    console.log("Types : " + types);

    if (typeof types === "string") {
      const typeList = types.split(", ").map((type) => type.trim());
      console.log("Types split : " + typeList);

      if (typeList.length === 1) {
        if (typeList[0] === "Document") {
          iconToRender = DocumentIcon;
        } else if (typeList[0] === "UserScreen") {
          iconToRender = UserScreenIcon;
        }
      } else if (typeList.length > 1) {
        // If all types are "Document"
        if (typeList.every((type) => type === "Document")) {
          iconToRender = MultiDocumentsIcon;
        }
        // If all types are "UserScreen"
        else if (typeList.every((type) => type === "UserScreen")) {
          iconToRender = MultiScreenIcon;
        }
        // If there is a mix of "Document" and "UserScreen"
        else {
          iconToRender = UserScreenDocumentIcon;
        }
      }
    } else if (types === null || types === 0) {
      iconToRender = FolderIcon;
    }

    // Fallback to FolderIcon if no valid types
    if (!iconToRender) {
      iconToRender = FolderIcon;
    }

    return (
      <td className="px-4">
        <img src={iconToRender} alt="Type Icon" className={styles["icon"]} />
      </td>
    );
  };

  const updateBreadcrumb = (
    item,
    currentFolders = folders,
    breadcrumbTrail = []
  ) => {
    currentFolders.forEach((folder) => {
      if (folder.workflowId === item.workflowId) {
        setBreadcrumb([
          homeBreadcrumb,
          ...breadcrumbTrail,
          { id: folder.name, text: folder.name },
        ]);
        return;
      } else if (folder.subFolders) {
        updateBreadcrumb(item, folder.subFolders, [
          ...breadcrumbTrail,
          { id: folder.name, text: folder.name },
        ]);
      }
    });
  };

  const TreeFolderItem = (props) => {
    return (
      <div
        role="button"
        className={`pt-1 fw-semibold ${styles["tree-Item"]} ${
          selected === props.item.workflowId ? styles["tree-Item-selected"] : ""
        }`}
      >
        <img
          src={FolderIcon}
          key="0"
          alt="Folder Icon"
          className={`${styles["icon"]}`}
        />
        <span className="ms-3 fs-sm">{props.item.text}</span>
      </div>
    );
  };

  const prepareTreeData = (treeData) => {
    const data = treeData?.map((item) => {
      const newTreeItem = { ...item };
      newTreeItem.text = item.name;
      if (item.subFolders === null) return newTreeItem;
      newTreeItem.items = prepareTreeData(item.subFolders);
      return newTreeItem;
    });
    return data;
  };

  const getWorkFlowFolders = async () => {
    try {
      const data = await getCaseWorkFlows(reference.appCode);
      prepareWorkFlowStructure(data.data?.data);
    } catch (error) {
      toast.error("Error occured while fetching records");
    }
  };

  const prepareWorkFlowStructure = (data) => {
    const preparedData = prepareTreeData(data);
    setFolders(preparedData);
    if (!selected) {
      setData(preparedData);
      setOriginalData(preparedData);
    }
  };

  const getCaseWorkFlowItems = async (parentId) => {
    try {
      if (parentId) {
        setParentId(parentId);
        const items = await getCaseWorkFlowsItem(reference.appCode, parentId);
        setData(items.data?.data);
        setOriginalData(items?.data?.data);
      }
    } catch (error) {
      toast.error("Error occured while fetching records");
    }
  };

  const getDocTemplateReunner = async (workflowId) => {
    try {
      if (parentId) {
        setShowDocTemplate(true);
        const data = await runDocumentTemplate(reference.matterId, workflowId);
        setDroppedFilesActivity([data.data?.data]);
        setAddActivity(true);
      }
    } catch (error) {
      toast.error("Error occured while fetching records");
      next();
    } finally {
      setShowDocTemplate(false);
    }
  };

  const getClientInterviewData = async (workflowId) => {
    try {
      if (parentId) {
        setShowClientInterview(true);
        setClientWFID(workflowId);
      }
    } catch (error) {
      toast.error("Error occured while fetching records");
    }
  };

  const onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
    setFolders([...folders]);
  };

  const onItemClick = (event) => {
    setSelected(event.item.workflowId);
    getCaseWorkFlowItems(event.item?.workflowId);
    updateBreadcrumb(event.item);
    onExpandChange(event);
  };

  const handleItemSelect = (event) => {
    if (event.id === "home") {
      setSelected("");
      setData(folders);
      setParentId(null);
      setBreadcrumb([homeBreadcrumb]);
      setRefreshData((prevState) => !prevState);
      return;
    }

    const selectedFolder = folders.find((folder) => folder.name === event.id);
    if (selectedFolder) {
      setSelected(selectedFolder.workflowId);
      getCaseWorkFlowItems(selectedFolder.workflowId);

      const index = breadcrumb.findIndex((item) => item.id === event.id);

      if (index !== -1) {
        setBreadcrumb(breadcrumb.slice(0, index + 1));
      }
    }
  };

  const onRowDoubleClick = (event) => {
    const selectedItem = event.dataItem;
    if (selectedItem.isFolder) {
      setSelected(selectedItem.workflowId);
      getCaseWorkFlowItems(selectedItem.workflowId);
      updateBreadcrumb(selectedItem);
      return;
    }
    const items = selectedItem?.workflowItems || [];

    if (items.length > 0) {
      setWorkflowItems(items);
      setCurrentIndex(0);
      setTimeout(() => {
        processStep(items[0]); // Start with the first item
      }, 500);
    }

    // for (let item of selectedItem?.workflowItems) {
    //   if (item.itemType === "UserScreen") {
    //     setInterviewId(item.userScreenId);
    //     setShowDialog(true);
    //   }
    //   if (item.itemType === "Document") {
    //     getDocTemplateReunner(item?.workflowItemId);
    //   }
    //   if (item.itemType === "Interview") {
    //     console.log("Interview", item);
    //     getClientInterviewData(item?.workflowItemId);
    //   }
    // }
  };

  const processStep = (item) => {
    if (!item) return; // Safety check

    if (item.itemType === "UserScreen") {
      setInterviewId(item.userScreenId);
      setShowDialog(true); // The dialog's onClose will call next()
    } else if (item.itemType === "Document") {
      getDocTemplateReunner(item?.workflowItemId); // Process Document, then call next
    } else if (item.itemType === "Interview") {
      getClientInterviewData(item?.workflowItemId); // Process Interview, then call next
    }
  };

  const CustomDelimiter = () => {
    return "/";
  };

  const onChange = (event) => {
    setPanes(event.newState);
  };

  const add = async (dataItem) => {
    const maxOrder = data.reduce((max, item) => Math.max(max, item.order), 0);
    dataItem.inEdit = false;
    if (
      !dataItem.name ||
      (dataItem.workflowItemsType === "UserScreen" && !dataItem.workflowItems)
    ) {
      return;
    }

    let newRecord = {
      ...dataItem,
      appCode: reference.appCode,
      active: true,
      parentId: parentId ? parentId : null,
      order: maxOrder + 1,
      isFolder:
        dataItem.workflowItemsType === 0 ||
        dataItem.workflowItemsType === null ||
        !dataItem.workflowItemsType,
    };

    if (!newRecord.isFolder) {
      newRecord.workflowItems =
        dataItem.workflowItems?.length > 0
          ? dataItem.workflowItems.map((item) => ({
              ...item,
              itemType: item.itemType || dataItem.workflowItemsType,
              active: true,
            }))
          : [];
    }

    try {
      await addCaseWorkflowRecord(newRecord);
      setRefreshData((prevState) => !prevState);
      setData((prevData) =>
        prevData.map((item) => (item.inEdit ? newRecord : item))
      );

      if (addFolder) {
        setAddFolder(false);
        toast.success("Folder Added");
      }

      if (addWorkflow) {
        setAddWorkFlow(false);
        toast.success("Work Flow Added");
      }

      setOriginalData((prevData) =>
        prevData.map((item) => (item.inEdit ? newRecord : item))
      );
    } catch (error) {
      toast.error("Error occured while adding records");
    }
  };

  const update = async (dataItem, isActive) => {
    const itemIndex = data.findIndex(
      (p) => p.workflowId === dataItem.workflowId
    );

    if (itemIndex !== -1) {
      const newData = [...data];
      newData[itemIndex] =
        isActive !== undefined
          ? { active: isActive }
          : {
              ...dataItem,
              inEdit: false,
              workflowItems:
                dataItem.workflowItems?.length > 0
                  ? [...dataItem.workflowItems].map((item) => ({
                      ...item,
                      itemType: item.itemType,
                    }))
                  : [],
            };

      try {
        await updateWorkFlow(dataItem.workflowId, newData[itemIndex]);
        setRefreshData((prevState) => !prevState);

        if (addFolder) {
          setEditItem();
          setAddFolder(false);
          toast.success("Folder Updated");
        }
        if (addWorkflow) {
          setEditItem();
          setAddWorkFlow(false);
          toast.success("Workflow Updated");
        }
      } catch (error) {
        toast.error(error);
        return;
      }
      setData(newData);
    }
  };

  const discard = () => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
  };

  const cancel = (dataItem) => {
    setData((prevData) => {
      const originalItem = originalData.find(
        (p) => p.workflowId === dataItem.workflowId
      );

      return prevData.map((item) =>
        item?.workflowId === originalItem?.workflowId ? originalItem : item
      );
    });
  };
  const enterEdit = (dataItem) => {
    if (dataItem.isFolder) {
      setEditItem(dataItem);
      setAddFolder(true);
    } else if (!dataItem.isFolder) {
      setEditItem(dataItem);
      setAddWorkFlow(true);
    }
  };
  const itemChange = (event) => {
    const newData = data.map((item) =>
      item.workflowId === event.dataItem.workflowId
        ? {
            ...item,
            [event.field || ""]: event.value,
          }
        : item
    );
    setData(newData);
  };

  const commandCellProps = {
    add: add,
    edit: enterEdit,
    update: update,
    cancel: cancel,
    discard: discard,
    editField: editField,
  };

  const Redirect = (props) => {
    return (
      <td>
        <AiOutlineRightCircle
          size={25}
          color="#000000"
          className="cursor-pointer"
          onClick={() => onRowDoubleClick(props)}
        />
      </td>
    );
  };

  return (
    <Splitter panes={panes} onChange={onChange} className={"workflow-splitter"}>
      <div className="workflow-container pt-3 ps-3">
        <TreeView
          data={folders}
          expandIcons={true}
          aria-multiselectable={false}
          onExpandChange={onExpandChange}
          onItemClick={onItemClick}
          item={TreeFolderItem}
          className={` workflow-treeview ${styles["treeview"]}`}
        />
      </div>

      <div className={!editWorkflow ? "workflow" : ""}>
        <div className="p-3 pt-4 mx-2">
          <div className={`${styles["breadcrumb-container"]}`}>
            <div className="d-flex align-items-center">
              {editWorkflow && (
                <div className={`ps-2 pe-2 d-flex align-items-center`}>
                  <p className="fw-semibold fs-md m-0">Edit Mode</p>
                </div>
              )}
              <Breadcrumb
                data={breadcrumb}
                onItemSelect={handleItemSelect}
                breadcrumbDelimiter={CustomDelimiter}
              />
            </div>

            {editWorkflow && (
              <div className="d-flex gap-2">
                <Button
                  className="button-small"
                  onClick={() => {
                    setAddFolder(true);
                  }}
                  disabled={!reference.appCode}
                >
                  Add Folder
                </Button>
                <Button
                  className="button-small"
                  disabled={!reference.appCode}
                  onClick={setAddWorkFlow}
                >
                  Add Workflow
                </Button>
              </div>
            )}
          </div>
          <ReorderContext.Provider
            value={{
              reorder: reorder,
              dragStart: dragStart,
            }}
          >
            <DragAndDrop>
              <Grid
                className="pb-1 mt-3 cursor-pointer"
                data={data}
                onItemChange={itemChange}
                editField={editField}
                onRowDoubleClick={onRowDoubleClick}
                rowRender={(row, rowProps) => (
                  <DraggableRow
                    elementProps={row.props}
                    {...rowProps}
                    appCode={reference.appCode}
                    parentId={parentId}
                    data={data}
                  />
                )}
                style={{ maxHeight: "calc(100vh - 400px)" }}
              >
                {editWorkflow && (
                  <GridColumn title=" " width={20} cell={DragHandleCell} />
                )}

                <GridColumn title="" width={50} cell={TypeIconCell} />

                {editWorkflow && (
                  <GridColumn
                    field="workflowItemsType"
                    title="Type"
                    cell={DropdownCell}
                    width={150}
                  />
                )}

                <GridColumn
                  className="gridBoldField"
                  field="name"
                  title="Name"
                  width={150}
                />

                <GridColumn field="details" title="Details" width={250} />

                {!editWorkflow && (
                  <GridColumn
                    field="lastRunDate"
                    title="Date"
                    cell={DateFormatCell}
                    width={120}
                  />
                )}

                {editWorkflow ? (
                  <GridColumn
                    cell={(props) => (
                      <CommandCell
                        {...props}
                        {...commandCellProps}
                        appCode={reference.appCode}
                      />
                    )}
                    width={85}
                  />
                ) : (
                  <GridColumn cell={Redirect} width={60} />
                )}
              </Grid>
            </DragAndDrop>
          </ReorderContext.Provider>

          {showDialog && interviewId !== null && (
            <UserScreen
              InterviewId={interviewId}
              matterId={matterId}
              onClose={() => {
                setShowDialog(false);
                next();
              }}
            />
          )}

          {showScreenDesigner && (
            <ScreenDesigner
              matterId={matterId}
              onClose={() => setShowScreenDesigner(false)}
            />
          )}

          {addFolder && (
            <AddFolder
              cancelEdit={() => setAddFolder(false)}
              isEdit={editItem && editItem?.isFolder ? editItem : false}
              addFolder={(name) => {
                add({
                  inEdit: false,
                  adding: true,
                  name: name,
                });
              }}
              updateFolder={(folder) => {
                update(folder);
              }}
            />
          )}

          {showDocTemplate && <DocTemplateRunner />}

          {/* {showClientInterview && (
                        <ClientInterviewDialog
                            onClose={() => {
                                setShowClientInterview(false);
                                next();
                            }}
                            matterId={matterId}
                            wfID={clientWFID}
                            feeEarnerRef={reference?.feeEarnerRef}
                        />
                    )} */}

          {addWorkflow && (
            <WorkFlowDialog
              cancelEdit={() => {
                setAddWorkFlow(false);
                if (editItem) setEditItem();
              }}
              matterId={matterId}
              isEdit={editItem && !editItem?.isFolder ? { ...editItem } : false}
              addWorkFlow={(data) => {
                add({
                  inEdit: false,
                  adding: true,
                  workflowItemsType: "UserScreen",
                  ...data,
                });
              }}
              editWorkFlow={(data) => {
                update(data);
              }}
            />
          )}

          {addActivity && (
            <CaseActivityForm
              getActivitiesData={() => {}}
              addFormText={reference?.matterRef + "-" + reference?.details}
              droppedFilesActivity={droppedFilesActivity}
              // viewActivity={viewActivity}
              feeEarnerRef={reference?.feeEarnerRef}
              // isView={isView}
              component={"DOC-TEMPLATE"}
              reference={reference.matterId}
              // onMatterRefClickActions={onMatterRefClickActions}
              setshowMatterForm={() => {
                setDroppedFilesActivity([]);
                setAddActivity(false);
                next();
              }}
            />
          )}
        </div>
      </div>
    </Splitter>
  );
}
