import React, { useCallback, useEffect, useRef, createRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import "react-router-dom";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridColumn,
  getSelectedState,
  getSelectedStateFromKeyDown,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { Splitter } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { toast } from "react-toastify";
import styles from "../Casefile.module.scss";
import { DateFormat } from "components/common/formatter.js";
import {
  getDocument,
  getActivityTypes,
  getDocumentEditor,
  getMatterDocDuplicate,
  updateMatterActivity,
  saveDocumentEditor,
} from "api/index";
import {
  downloadDocumentAsPDF,
  downloadDocument,
  downloadDocumentsAsZip,
} from "api/index";

import { handleApiError } from "Helper";
import CaseActivityForm from "./CaseActivityForm";
import DocumentViewer from "components/common/DocumentViewer";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";
import { fileIcon } from "components/common/fileIcon";
import { useDoubleClick } from "components/common/hooks/SingleDoubleClick";

import Loader from "../../../components/common/Loading";
import ShareDocumentModal from "../ShareDocumentModal";
import { HandleGridBody } from "components/common/HandleGridBody";
import FilterComponent from "components/CaseFiles/FilterComponent";
import DocumentEditor from "components/common/EditDocumentDialog";

import ConfirmationDialog from "components/common/ConfirmationDialog";
import ESigning from "../ESigning";
import MenuCell from "./MenuCell";

const DOC_OPEN_PANES = [
  {
    size: "60%",
    max: "60%",
    collapsible: true,
  },
  {},
];

const DEFAULT_PANES = [
  {
    size: "100%",
    max: "70%",
    collapsible: true,
  },
  {},
];

const DATA_ITEM_KEY = "activityId";

const CaseActivitiesPage = (props) => {
  const idGetter = getter(DATA_ITEM_KEY);

  const SELECTED_FIELD = "selected";
  const uploadRef = createRef();
  const [eventDataState, setEventDataState] = useState();
  const [filterFeeEarner, setFillterFeeEarner] = useState();
  const [showDocument, setShowDocument] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [editDocumentDialog, setEditDocumentDialog] = useState();
  const [eSigning, setESigning] = useState();
  const [activityTypes, setActivityTypes] = useState([]);
  const [droppedFilesActivity, setDroppedFilesActivity] = useState([]);
  const { yearStartDate } = useSelector((state) => state.company.company);
  const [selectedState, setSelectedState] = useState({});
  const [paneDocument, setPaneDocument] = useState([]);
  const [panes, setPanes] = useState(DEFAULT_PANES);
  const [loader, setLoader] = useState(false);
  const [showShareDocument, setShowShareDocument] = useState(false);
  const [currentDoc, setCurrentDoc] = useState({});
  const [popupState, setPopupState] = useState(false);
  const [reload, setReload] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [updateFilter, setUpdateFilter] = useState(false);
  const [typeID, setTypeID] = useState({ name: "All", value: "" });
  const [multiSel, setMultiSel] = useState(false);
  let date = new Date();

  const [toDateSelector, setToDateSelector] = useState(date);
  let yearsStartDate = new Date(yearStartDate);

  const [fromDate, setFromDate] = useState(
    yearsStartDate.toISOString().split("T")[0]
  );

  const [toDate, setToDate] = useState(date.toISOString().split("T")[0]);
  const [fromDateSelector, setFromDateSelector] = useState(yearsStartDate);

  const [list, setLists] = useState({
    data: [],
    total: 20,
  });
  const [filterParameters, setFilterParmeters] = useState({
    postingStatus: "false",
  });

  const [show, setShow] = useState(false);
  const [valueFrom, setValueFrom] = useState(false);
  const [valueTo, setValueTo] = useState(false);
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  const [state, setState] = useState(createState(0, 50));
  const [viewActivity, setViewActivity] = useState();
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isView, setView] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [dataState, setDataState] = useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "date",
        dir: "desc",
      },
    ],
  });

  const [feeEarners, setfeeEarners] = useState([]);
  const feeearnerdata = useSelector((state) => {
    const preparedData = prepareFeeearnerData(
      state?.postingsdocs?.feeearner?.data
    );
    return preparedData;
  });

  const feeearnerList = useSelector(
    (state) => state.postingsdocs.feeearner?.data
  );

  const blurTimeoutRef = useRef(null);

  const getActivityType = async () => {
    const { data } = await getActivityTypes();
    setActivityTypes(data.data.slice());
  };

  useEffect(() => {
    getActivityType();
  }, []);

  useEffect(() => {
    if (props.refreshClicked && props.activeItem === 0) {
      handleRefresh();
      props?.setRefreshClicked(false);
    }
  }, [props?.refreshClicked]);

  const onOpen = () => {
    props?.filterContentRef?.current.focus();
  };

  const onFocus = () => {
    clearTimeout(blurTimeoutRef.current);
  };

  const onBlurTimeout = () => {
    props?.setFilterClicked(false);
  };

  const onBlur = () => {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(blurTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (updateFilter) {
      setUpdateFilter(false);
    }
  }, [updateFilter]);

  const onChange = (event) => {
    setPanes(event.newState);
  };

  const handleDrop = (event) => {
    setDroppedFilesActivity([...event]);
    props.setAddActivity(true);
    // onFilesDrop(files);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setMultiSel(true);
    setSelectedState(newSelectedState);
    showPaneFile(
      list?.data?.filter(
        (item) => item.activityId == Object.keys(newSelectedState)[0]
      )[0]?.documentId
    );
  };

  const onKeyDown = (event) => {
    if (event.nativeEvent.code === "Enter") {
      const newSelectedState = getSelectedStateFromKeyDown({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      showPaneFile(
        list?.data?.filter(
          (item) => item.activityId == Object.keys(newSelectedState)[0]
        )[0]?.documentId
      );
    }
  };

  const handleTypeChange = (value) => {
    setTypeID({
      name: value.value?.name,
      value: value.value?.activityTypeID || value.value?.value,
    });
  };

  const onChangeFromDateSelect = (event) => {
    if (!valueFrom) {
      setValueFrom(true);
    }
    setFromDateSelector(event.value);
    let fromDate = null;
    let date = new Date(event.value);
    if (event.value) {
      fromDate = date.toISOString().split("T")[0];
    }
    setFromDate(fromDate);
  };

  const onMatterRefClickActions = () => {};

  const onChangeToDateSelect = (event) => {
    if (!valueTo) {
      setValueTo(true);
    }
    setToDateSelector(event.value);
    let toDate = null;
    let date = new Date(event.value);
    if (event.value) {
      toDate = date.toISOString().split("T")[0];
    }
    setToDate(toDate);
  };

  const updateFilterValues = () => {
    const { setFilterClicked, setFilterApplied } = props;
    setUpdateFilter(true);
    setFilterClicked(false);

    if (!valueFrom && !valueTo && typeID?.value === "") {
      setFromDateSelector(yearsStartDate);
      setToDateSelector(date);
      setFilterApplied(false);
      return;
    }
    setFilterApplied(true);
  };

  const deleteMatteractivity = async (data) => {
    try {
      await updateMatterActivity(props.matterId, data?.activityId, {
        ...data,
        active: false,
      });
      setDeleteConfirmation();
      getActivitiesData();
    } catch (error) {}
  };

  const handleDownload = async (documentId, asPDF = false, fromDocView) => {
    setLoader(true);

    try {
      const selectedDocumentIds = Object.keys(selectedState)
        .filter((key) => selectedState[key]) // Only include keys where selectedState is true
        .map((key) => {
          const selectedItem = list?.data?.find(
            (item) => item.activityId == key
          );
          return selectedItem?.documentId;
        })
        .filter((id) => id); // Remove undefined ids

      let response;
      let fileName = "downloaded-document.pdf";

      if (selectedDocumentIds.length > 1 && !fromDocView) {
        // If multiple documents are selected, download as zip
        const zipFileName =
          props?.matterDetails?.matterRef?.replace(/\//g, "-") + ".zip" ||
          "downloaded-document.zip";
        response = await downloadDocumentsAsZip(
          zipFileName,
          selectedDocumentIds
        );
        fileName = zipFileName;
      } else if (selectedDocumentIds.length === 1 || documentId) {
        response = asPDF
          ? await downloadDocumentAsPDF(documentId || selectedDocumentIds[0])
          : await downloadDocument(documentId || selectedDocumentIds[0]);

        // Extract filename from content-disposition header
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          }
        }
      } else {
        // No documents selected
        return;
      }

      const blob = new Blob([response.data]);

      const url = window.URL.createObjectURL(blob);

      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", fileName);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleDuplicate = async (matterId, activityId) => {
    try {
      const { data } = await getMatterDocDuplicate(matterId, activityId);
      props.setAddActivity(true);
      setViewActivity(data?.data);
      setView(true);
      setIsDuplicate(true);
    } catch (error) {}
  };

  const getActivitiesData = async () => {
    // setReload(!reload);
    setIsRefreshed(!isRefreshed);
  };

  const handleRefresh = () => {
    if (eventDataState) {
      setDataState({
        ...eventDataState?.event,
        skip: 0,
      });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
    setIsRefreshed(!isRefreshed);
    setUpdateFilter(true);
  };

  const handleReset = () => {
    setUpdateFilter(true);
    setValueFrom(false);
    setValueTo(false);
    setFromDateSelector(yearsStartDate);
    setToDateSelector(date);
    setTypeID({ name: "All", value: "" });
    props.setFilterApplied(false);
    props.setFilterClicked(false);
  };

  const onChangePosting = (event) => {
    let tempData = event.value;
    setfeeEarners([...event.value]);
    let parametersArray = [];
    feeearnerList.map((item) => {
      if (tempData.includes(item.displayname)) {
        parametersArray.push(item.userRef);
      }
    });
    setFillterFeeEarner(parametersArray);
  };

  const [documentViewerData, setDocumentViewerData] = useState([]);

  const showDocumentFile = async (id) => {
    const { data } = await getDocument(id);
    setDocumentViewerData([data.data]);
    setShowDocument(true);
  };

  const getDocumentEditorData = async (id, type) => {
    try {
      setLoader(true);
      const { data } = await getDocumentEditor(id, props.matterId);
      let editUrl = data?.data?.editURL;
      if (type === "Local") {
        editUrl = `ms-word:ofe|u|${data?.data?.editURL}`;
      }
      window.open(editUrl, "_blank");
      showPaneFile(id);
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode === 460) {
        toast.info(error?.response?.data?.userMessage);
      } else {
        toast.error("Error occurred while editing");
      }
    } finally {
      setLoader(false);
    }
  };

  const showPaneFile = async (id) => {
    setLoaded(false);
    try {
      if (id) {
        const { data } = await getDocument(id);
        setPaneDocument([data.data]);
        setPanes(DOC_OPEN_PANES);
      }
    } catch (error) {
      setLoaded(false);
    } finally {
      setLoaded(true);
    }
  };

  function truncateFileName(fileName) {
    if (fileName) {
      const extensionIndex = fileName.lastIndexOf(".");

      if (extensionIndex === -1) {
        return fileName.length > 18 ? fileName.substring(0, 18) : fileName;
      }

      const extension = fileName.substring(extensionIndex);

      if (fileName.length > 18) {
        return fileName.substring(0, 17 - extension.length) + extension;
      }

      return fileName;
    }
  }

  const DocumentCell = (e) => {
    const { documentId, documentName } = e.dataItem;

    const hybridClick = useDoubleClick(
      () => showDocumentFile(documentId),
      () => {
        setSelectedState({
          [e.dataItem.activityId]: true,
        });
        showPaneFile(documentId);
      }
    );

    return documentId ? (
      <td className="p-1" {...e} onClick={hybridClick}>
        <div role="button" className="d-flex">
          {fileIcon(documentName)}

          <span className="ps-2 fs-sm d-lg-none d-xl-block">
            {truncateFileName(documentName)}
          </span>

          <span className="ps-2 fs-sm d-xl-none d-lg-block">
            {documentName?.split(".").pop()}
          </span>
        </div>
      </td>
    ) : (
      <td></td>
    );
  };

  const saveDocuemntEdit = async (id) => {
    try {
      await saveDocumentEditor(id, props.matterId);
      toast.success("Document Saved");
      showPaneFile(id);
    } catch (error) {
      toast.error("Error occured while editing");
    } finally {
      // setLoadingScreen(false);
    }
  };

  const DateCell = (props) => {
    const dateField = props.dataItem[props.field];

    if (dateField) {
      return (
        <td
          onClick={() => {
            setSelectedState({
              [props.dataItem.activityId]: true,
            });
            showPaneFile(props.dataItem?.documentId);
          }}
          className={props.className}
        >
          {DateFormat(dateField)}{" "}
        </td>
      );
    } else {
      return <td></td>;
    }
  };

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
    setUpdateFilter(true);
  };

  const TypeCell = (prop) => {
    return (
      <td>
        <u
          style={{ cursor: "pointer" }}
          onClick={(select) => {
            setSelectedState({
              [prop.dataItem.activityId]: true,
            });
            props.setAddActivity(true);
            setViewActivity(prop.dataItem);
            setView(true);
          }}
        >
          {prop.dataItem.typeCode}
        </u>
      </td>
    );
  };

  const dataReceived = (data) => {
    if (data) {
      setLists(data);
    }
  };

  const mappedData = {
    data: list?.data?.map((item) => ({
      ...item,
      [SELECTED_FIELD]: selectedState[idGetter(item)],
    })),
    total: list?.total,
  };

  return (
    <div className="container-fluid p-0 mt-3 activity-grid">
      {loader && <Loader />}
      <Splitter
        panes={panes}
        onChange={onChange}
        className={"workflow-splitter"}
      >
        <FileDragDrop onFilesDrop={handleDrop} uploadRef={uploadRef}>
          <Grid
            {...dataState}
            data={mappedData}
            sortable={true}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onDataStateChange={dataStateChange}
            onSelectionChange={onSelectionChange}
            onKeyDown={onKeyDown}
            className="fs-md grid-row-select casefile_activities_gridheight"
            pageable={state.pageable}
            pageSize={state.pageSize}
          >
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              headerCell={() => <></>}
            />

            <GridColumn
              field="typeCode"
              title="Type"
              cell={TypeCell}
              width="60px"
            />
            <GridColumn
              field="date"
              title="Date"
              cell={DateCell}
              width="100px"
              className="p-1 cursor-pointer"
            />
            <GridColumn field="feeEarnerRef" title="FE" width="60px" />
            <GridColumn field="details" title="Details" />
            <GridColumn
              field=""
              title=" "
              cell={(prop) => (
                <MenuCell
                  {...prop}
                  loaded={loaded}
                  setLoaded={setLoaded}
                  setSelectedState={setSelectedState}
                  selectedState={selectedState}
                  showPaneFile={showPaneFile}
                  multiSel={multiSel}
                  setMultiSel={setMultiSel}
                  setAddActivity={props.setAddActivity}
                  setViewActivity={setViewActivity}
                  setView={setView}
                  showDocumentFile={showDocumentFile}
                  handleDownload={handleDownload}
                  getDocumentEditorData={getDocumentEditorData}
                  handleDuplicate={handleDuplicate}
                  setShowShareDocument={setShowShareDocument}
                  setCurrentDoc={setCurrentDoc}
                  setDeleteConfirmation={setDeleteConfirmation}
                  setESigning={setESigning}
                />
              )}
              width={"40px"}
            />
            <GridColumn title="Document" cell={DocumentCell} width="180px" />
            <GridColumn title="Email" width="180px" className="fs-sm" />
            {/*<GridColumn field="status" title="Status" width="80px" />*/}
          </Grid>
        </FileDragDrop>

        <div>
          <DocumentViewer
            loader={loaded}
            matterId={props?.matterId}
            onDownload={handleDownload}
            saveDocuemntEdit={saveDocuemntEdit}
            data={paneDocument}
            onRefresh={(id) => showPaneFile(id)}
            onScreenComponent={true}
            onEditDoc={(e, type) => getDocumentEditorData(e, type)}
            onClose={() => {
              setPanes(DEFAULT_PANES);
              setPaneDocument([]);
              setSelectedState([]);
            }}
            onExpand={() => {
              if (panes[0].collapsed) {
                setPanes(DOC_OPEN_PANES);
              } else {
                setPanes([
                  {
                    collapsed: true,
                  },
                  {},
                ]);
              }
            }}
          />
        </div>
      </Splitter>
      <Popup
        show={props?.filterClicked}
        anchor={props?.filterAnchor?.current}
        anchorAlign={{
          horizontal: "right",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "right",
          vertical: "top",
        }}
        popupClass={`${styles["popup-content"]}`}
        onOpen={onOpen}
      >
        <div
          tabIndex={0}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={props?.filterContentRef}
          role="menu"
          className="d-flex flex-column align-items-center"
        >
          <FilterComponent
            updateFilterValues={updateFilterValues}
            setUpdateFilter={setUpdateFilter}
            updateFilter={updateFilter}
            showPopup={true}
            valueFrom={valueFrom}
            setValueFrom={setValueFrom}
            fromDateSelector={fromDateSelector}
            onChangeFromDateSelect={onChangeFromDateSelect}
            valueTo={valueTo}
            setValueTo={setValueTo}
            toDateSelector={toDateSelector}
            onChangeToDateSelect={onChangeToDateSelect}
            typeData={[{ name: "All", value: "" }, ...activityTypes]}
            typeID={typeID}
            handleChange={handleTypeChange}
            handleReset={handleReset}
          />
        </div>
      </Popup>

      <HandleGridBody
        matterId={props.matterId}
        feeEarner={filterFeeEarner}
        typeId={typeID?.value}
        refresh={isRefreshed}
        updateFilter={updateFilter}
        fD={updateFilter && valueFrom ? fromDate : ""}
        tD={updateFilter && valueTo ? toDate : ""}
        dataState={dataState}
        onDataReceived={dataReceived}
        reload={reload}
        currentComponent="caseActivities"
      />

      {props.addActivity && (
        <CaseActivityForm
          getActivitiesData={getActivitiesData}
          addFormText={props.addFormText}
          droppedFilesActivity={droppedFilesActivity}
          viewActivity={viewActivity}
          feeEarnerRef={props.matterDetails?.feeEarnerRef}
          isView={isView}
          isDuplicate={isDuplicate}
          reference={props.matterId}
          onMatterRefClickActions={onMatterRefClickActions}
          setshowMatterForm={() => {
            setViewActivity();
            setView(false);
            setDroppedFilesActivity([]);
            if (isDuplicate) setIsDuplicate(false);
            props.setAddActivity(false);
          }}
        />
      )}
      {showDocument && (
        <DocumentViewer
          matterId={props?.matterId}
          onRefresh={(id) => showPaneFile(id)}
          data={documentViewerData}
          saveDocuemntEdit={saveDocuemntEdit}
          onEditDoc={(e) => getDocumentEditorData(e)}
          onClose={() => {
            setShowDocument(false);
            setDocumentViewerData([]);
          }}
          onDownload={handleDownload}
          component={"CASE-FILES"}
        />
      )}

      {eSigning && (
        <ESigning
          matterId={props?.matterId}
          title={"Select Contacts"}
          activityId={eSigning}
          onClose={() => setESigning()}
        />
      )}

      {editDocumentDialog && (
        <DocumentEditor
          documentId={editDocumentDialog}
          cancelEdit={(isSucess) => {
            setEditDocumentDialog();
            if (isSucess) getActivitiesData();
          }}
          matterId={props.matterId}
          onDownload={handleDownload}
        />
      )}

      {showShareDocument && (
        <ShareDocumentModal
          onClose={() => setShowShareDocument(false)}
          selectedDocuments={Object.keys(selectedState)
            .filter((key) => selectedState[key])
            .map((key) => {
              const selectedItem = list?.data?.find(
                (item) => item.activityId == key
              );
              return {
                id: selectedItem?.documentId,
                name: selectedItem?.documentName,
              };
            })
            .filter((item) => item.id && item.name)}
          matterId={props.matterId}
          currentDoc={currentDoc}
        />
      )}

      {deleteConfirmation && (
        <ConfirmationDialog
          content={`Are you sure you want to delete activity '${deleteConfirmation?.details}'?`}
          onClickYes={() => {
            deleteMatteractivity(deleteConfirmation);
          }}
          onClickNo={() => setDeleteConfirmation()}
          onClose={() => setDeleteConfirmation()}
        />
      )}
    </div>
  );
};
export default CaseActivitiesPage;

export const FileDragDrop = ({ children, onFilesDrop }) => {
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      const files = e.dataTransfer.files;
      onFilesDrop(files);
    },
    [onFilesDrop]
  );

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={`drop-zone ${dragging ? "dragging" : ""}`}
    >
      {children}
    </div>
  );
};
