import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import styles from "../Casefile.module.scss";
import infoIcon from "../../../assets/CaseFileIcons/info-icon.png";
import { Button } from "@progress/kendo-react-buttons";
import {
  getActivityTypes,
  getDocument,
  saveActivity,
  updateMatterActivity,
  getMatterRates,
  getfeeearner,
} from "api/index";
import {
  FormComboBox,
  FormInput,
  FormDate,
  FormNumericTextBox,
} from "components/Matters/index";
import { CONSTANT } from "CONSTANT/Matter/MatterActivityForm";
import MatterModal from "components/Matters/MatterModal";
import { filterBy } from "@progress/kendo-data-query";
import { DocumentsUpload } from "pages/Postings/DcoumentsUpload";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { Window } from "@progress/kendo-react-dialogs";
import { setMainLoading } from "actions/common.action";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FiAlertCircle } from "react-icons/fi";
import fileIcon from "components/common/fileIcon";
import { prepareFeeearnerData } from "components/common/ComboRepresentationFunctions";
import { ContainerCard } from "components/MatterDetails/ContactCard";
import { Tile } from "components/UIComponents/Tile";
import { FileDragDrop } from "./CaseActivitiesPage";
import DocumentViewer from "components/common/DocumentViewer";
import { RiUpload2Fill } from "react-icons/ri";
import { toast } from "react-toastify";

const CaseActivityForm = (props) => {
  const matterDetails = useSelector((state) => state.mattersList);
  const [documentUpload, setDocumentsUpload] = useState(false);
  const { isSuccess, uploadForm, progress, reset } = useUploadForm();

  const { feeearner } = useSelector((state) => state.postingsdocs);
  const [details, setDetails] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [rateInfoText, setRateInfoText] = useState(0);
  const [modalSuccess, setModalSuccess] = useState(undefined);
  const [newMaterId, setNewMatterId] = useState(undefined);
  const [viewFile, setViewFile] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [inputState, setInputState] = useState({
    [CONSTANT.INPUT.DETAILS]: "",
    [CONSTANT.INPUT.UNITS]: !props.view ? 1 : 0,
    [CONSTANT.INPUT.MPU]: !props.view ? 6 : 0,
    [CONSTANT.INPUT.RATE]: 0.0,
    [CONSTANT.INPUT.VALUE]: 0.0,
  });

  const [comboBoxState, setComboBoxState] = useState({
    [CONSTANT.COMBOBOX.TYPE]: "",
    [CONSTANT.COMBOBOX.FEE_EARNER]: "",
  });

  const [dateState, setDateState] = useState({
    [CONSTANT.DATE.DATE]: new Date(),
  });
  const [feeEarner, setFeeEarner] = useState(feeearner.data);
  const [showPdf, setShowPdf] = useState(false);

  const filterFeeEarner = (filter) => {
    const data = feeearner.data.slice();
    return filterBy(data, filter);
  };
  const feeEarnerfilterChange = (event) => {
    setFeeEarner(filterFeeEarner(event.filter));
  };

  const getFeeEarnerData = async () => {
    try {
      const { data } = await getfeeearner();
      const preparedData = prepareFeeearnerData(data.data);
      setFeeEarner(preparedData);
      if (!props.isView) {
        setComboBoxState({
          ...comboBoxState,
          [CONSTANT.COMBOBOX.FEE_EARNER]: {
            displayname: preparedData?.find(
              (item) => item.userRef === props?.feeEarnerRef
            )?.displayname,
            userRef: props?.feeEarnerRef,
          },
        });
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parseDate = (newDate) => {
      if (!newDate) return null;
      var os = newDate?.getTimezoneOffset();
      return (newDate = new Date(newDate.getTime() - os * 60 * 1000));
    };

    const dataObject = {
      typeId: comboBoxState[CONSTANT.COMBOBOX.TYPE].activityTypeID || null,
      date: parseDate(dateState[CONSTANT.DATE.DATE]) || null,
      details: details,
      feeEarnerRef: comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER].userRef || null,
      units: parseInt(inputState[CONSTANT.INPUT.UNITS]) || null,
      mpu: parseInt(inputState[CONSTANT.INPUT.MPU]) || null,
      rate: parseFloat(inputState[CONSTANT.INPUT.RATE]) || null,
      value: parseFloat(inputState[CONSTANT.INPUT.VALUE]) || null,
      fileId: fileId.length ? fileId : null,
      subType: "None",
    };
    if (dataObject.details === "" || dataObject.feeEarnerRef === null) {
      setError(true);
      return;
    }
    dispatch(setMainLoading(true));
    if (props.isView && !props?.isDuplicate) {
      try {
        const res = await updateMatterActivity(
          props.viewActivity.matterId,
          props.viewActivity.activityId,
          dataObject
        );

        if (res?.status === 200) {
          dispatch(setMainLoading(false));
          // updateModalState(true, "Matter Activity Updated Successfully", true);
          toast.success("Activity Updated Sucessfully");
          props.getActivitiesData();
          props.setshowMatterForm(false);
        }
        return;
      } catch (err) {
        if (
          err?.response?.data?.errorMessage.includes(
            "The duplicate key value is"
          )
        ) {
          dispatch(setMainLoading(false));
          toast.error("Error occured adding activity.");
        } else {
          dispatch(setMainLoading(false));
          // updateModalState(true, "Error! Matter Update Failed", false);
          toast.error("Error occured adding activity.");
        }
      }
    } else {
      try {
        const res = await saveActivity(props.reference, dataObject);
        if (res?.status === 200) {
          dispatch(setMainLoading(false));
          toast.success("Activity Added Sucessfully");
          props.getActivitiesData();
          props.setshowMatterForm(false);
          setNewMatterId(res.data.data.matterId);
        }
      } catch (err) {
        if (
          err?.response?.data?.errorMessage?.includes(
            "The duplicate key value is"
          )
        ) {
          dispatch(setMainLoading(false));
          // updateModalState(true, "Matter Activity Already Added", false);
          toast.success("Error occured during adding");
        } else {
          dispatch(setMainLoading(false));
          // updateModalState(true, "Error Occured", false);
          toast.success("Error occured during adding");
        }
      }
    }
    setError(false);
  };

  React.useEffect(() => {
    if (props.isView) {
      getMatterData();
    }
  }, [props.isView]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const updateModalState = (modalShow, message, success) => {
    setShowModal(modalShow);
    setModalMessage(message);
    setModalSuccess(success);
  };

  const getDocuemntData = async (id) => {
    try {
      if (id) {
        const { data } = await getDocument(id);
        setFilesData([data?.data?.documentFile]);
      }
    } catch (error) {}
  };

  const getMatterData = async () => {
    setDetails(props.viewActivity.details);
    setInputState({
      [CONSTANT.INPUT.MPU]: props.viewActivity?.mpu,
      [CONSTANT.INPUT.RATE]: props.viewActivity?.rate,
      [CONSTANT.INPUT.UNITS]: props.viewActivity?.units,
      [CONSTANT.INPUT.VALUE]: props.viewActivity?.value,
    });

    setComboBoxState({
      [CONSTANT.COMBOBOX.FEE_EARNER]: {
        displayname: feeearner?.data?.find(
          (item) => item.userRef === props.viewActivity.feeEarnerRef
        )?.displayname,
        userRef: props.viewActivity.feeEarnerRef,
      },
      [CONSTANT.COMBOBOX.TYPE]: {
        name: props.viewActivity.type,
        activityTypeID: props.viewActivity.typeId,
      },
    });

    setDateState({
      [CONSTANT.DATE.DATE]: new Date(props.viewActivity.date),
    });
    let dd = "";
    if (
      props.viewActivity.documentId ||
      props.viewActivity.documentFile?.downloadURL
    ) {
      if (props.viewActivity.documentId) {
        dd = await getDocuemntData(props.viewActivity.documentId);
      }
      const downLoadUrl = await fetch(
        dd?.data?.data?.documentFile?.downloadURL ||
          props.viewActivity.documentFile?.downloadURL
      );
      if (dd?.data && !dd.data) {
        setFilesData([props.viewActivity.documentFile]);
        setFileId(props.viewActivity.documentFile?.fileID);
      } else if (props.isDuplicate) {
        setFilesData([props.viewActivity.documentFile]);
        setFileId(props.viewActivity.documentFile?.fileID);
      }
      const blob = await downLoadUrl.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        const cleanBase64 = base64data.split(",")[1];
        setViewFile(cleanBase64);
        return base64data;
      };
    }
  };

  const handleInputChange = (value, label) => {
    setInputState({
      ...inputState,
      [label]: value,
    });
  };

  const handleComboBoxChange = (e, label) => {
    setComboBoxState({
      ...comboBoxState,
      [label]: e.value,
    });
  };

  const handleDrop = (event) => {
    if (!props.docId?.documentId) {
      handleUpload({
        target: {
          files: [...event],
        },
      });
    }
  };

  const handleDateChange = (e, label) => {
    setDateState({
      ...dateState,
      [label]: e.value,
    });
  };

  const getActivityType = async () => {
    const { data } = await getActivityTypes();
    setActivityTypes(data.data.slice());
  };

  React.useEffect(() => {
    getFeeEarnerData();
    getActivityType();
    // getSingleActivity()
  }, []);

  const handleTypeChange = (value) => {
    setComboBoxState({
      ...comboBoxState,
      [CONSTANT.COMBOBOX.TYPE]: value.value,
    });
  };
  const [filesData, setFilesData] = React.useState([]);
  const [fileId, setFileId] = React.useState([]);
  const filePickerRef = React.useRef();
  const uploadRef = React.createRef();

  const handleUpload = async (e) => {
    try {
      setIsFileLoading(true);
      let data = onFileUpload(e);
      const dataChoosen = await uploadForm(data, e.target.files[0], {
        documentType: FileDocumentTypes.CaseFile,
        matterId: props.reference,
      });
      data.fileID = dataChoosen.data.data.fileID;
      setFileId(data.fileID);
      setFilesData([
        {
          ...dataChoosen?.data?.data,
        },
      ]);
      switch (dataChoosen?.data?.data?.filename?.split(".").pop()) {
        case "eml":
        case "msg":
          setComboBoxState({
            ...comboBoxState,
            [CONSTANT.COMBOBOX.TYPE]:
              activityTypes.filter((item) =>
                item.name?.toLowerCase()?.includes("email")
              )[0] || null,
          });
          break;
        case "doc":
        case "docx":
          setComboBoxState({
            ...comboBoxState,
            [CONSTANT.COMBOBOX.TYPE]:
              activityTypes.filter((item) =>
                item.name?.toLowerCase()?.includes("document")
              )[0] || null,
          });
          break;
        default:
          break;
      }
    } catch (error) {
    } finally {
      setIsFileLoading(false);
    }
  };

  const handleDocTemplate = async (data) => {
    try {
      setIsFileLoading(true);
      setFileId(data.fileID);
      setFilesData([
        {
          ...data,
        },
      ]);
      switch (data?.filename?.split(".").pop()) {
        case "eml":
        case "msg":
          setComboBoxState({
            ...comboBoxState,
            [CONSTANT.COMBOBOX.TYPE]:
              activityTypes.filter((item) =>
                item.name?.toLowerCase()?.includes("email")
              )[0] || null,
          });
          break;
        case "doc":
        case "docx":
          setComboBoxState({
            ...comboBoxState,
            [CONSTANT.COMBOBOX.TYPE]:
              activityTypes.filter((item) =>
                item.name?.toLowerCase()?.includes("document")
              )[0] || null,
          });
          break;
        default:
          break;
      }
    } catch (error) {
    } finally {
      setIsFileLoading(false);
    }
  };
  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  React.useEffect(() => {
    if (props.droppedFilesActivity?.length) {
      if (props.component === "DOC-TEMPLATE") {
        handleDocTemplate(props.droppedFilesActivity[0]);
      } else {
        handleUpload({
          target: {
            files: props.droppedFilesActivity,
          },
        });
      }
    }
  }, [props.droppedFilesActivity]);
  const getMatterRateValue = async () => {
    try {
      const data = await getMatterRates(
        props.reference,
        comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER]?.userRef
      );
      setInputState({
        ...inputState,
        [CONSTANT.INPUT.RATE]: data?.data?.data?.rateValue,
      });
      setRateInfoText(data?.data?.data?.rateInfo);
    } catch (error) {}
  };
  const calculateValue = () => {
    if (
      inputState[CONSTANT.INPUT.RATE] &&
      inputState[CONSTANT.INPUT.UNITS] &&
      inputState[CONSTANT.INPUT.MPU]
    ) {
      setInputState((prevState) => {
        const value =
          (prevState[CONSTANT.INPUT.RATE] / 60) *
          (prevState[CONSTANT.INPUT.UNITS] * prevState[CONSTANT.INPUT.MPU]);
        const newState = {
          ...prevState,
          [CONSTANT.INPUT.VALUE]: value,
        };
        return newState;
      });
    }
  };
  React.useEffect(() => {
    calculateValue();
  }, [
    inputState[CONSTANT.INPUT.RATE],
    inputState[CONSTANT.INPUT.UNITS],
    inputState[CONSTANT.INPUT.MPU],
  ]);

  React.useEffect(() => {
    if (comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER] !== "" && !props.isView)
      getMatterRateValue();
  }, [comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER]]);

  return (
    <div className={styles["matter-form-dialog"]}>
      <Dialog
        // className={styles["matter-form-dialog"]}
        title={
          props.isView && !props?.isDuplicate
            ? "Update Activity"
            : "Add Activity"
        }
        onClose={props.setshowMatterForm}
      >
        <div
          className="pb-5 overflow-hidden"
          style={{ width: "1000px", height: "75vh" }}
        >
          <form id="matterForm" onSubmit={handleSubmit}>
            <div className={`row`}>
              <div
                style={{
                  width: "350px",
                  height: "75vh",
                }}
                className="bgForm py-4 px-5 col-3"
              >
                <div className="">
                  <div className="">
                    <span className={`${styles["add-activity-form-text"]}`}>
                      {props.addFormText}
                    </span>
                  </div>
                  <FormComboBox
                    // required={true}
                    isStyled={true}
                    className="mt-4"
                    width={"260px"}
                    wf={true}
                    name={CONSTANT.COMBOBOX.TYPE}
                    styles={{
                      border:
                        error === true &&
                        !comboBoxState[CONSTANT.COMBOBOX.TYPE] &&
                        "1px solid red",
                    }}
                    textField={"name"}
                    state={comboBoxState}
                    data={activityTypes}
                    val={comboBoxState[CONSTANT.COMBOBOX.TYPE]}
                    onChangeComboBox={handleTypeChange}
                  />

                  <FormDate
                    isStyled={true}
                    className="note-date-picker mt-3"
                    label={CONSTANT.DATE.DATE}
                    onChangeDate={handleDateChange}
                    state={dateState}
                  />

                  <FormComboBox
                    // required={true}
                    isStyled={true}
                    className="mt-4"
                    width={"260px"}
                    wf={true}
                    styles={{
                      border:
                        error === true &&
                        !comboBoxState[CONSTANT.COMBOBOX.FEE_EARNER] &&
                        "1px solid red",
                    }}
                    onChangeComboBox={handleComboBoxChange}
                    onFilterChange={feeEarnerfilterChange}
                    data={feeEarner}
                    textField="displayname"
                    state={comboBoxState}
                    name={CONSTANT.COMBOBOX.FEE_EARNER}
                  />
                  <div className="col-12 d-flex flex-column mt-4">
                    <Label className={`label`}>Details</Label>
                    <TextArea
                      // required
                      placeholder="Enter Details"
                      autoSize={true}
                      value={details}
                      style={{
                        height: "92px",
                        width: "260px",
                        border: error === true && !details && "1px solid red",
                      }}
                      onChange={(e) => setDetails(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-9"
                style={{
                  width: "665px",
                }}
              >
                <Tile
                  heading="Time Record"
                  headingClass=""
                  className=" m-3 p-3 ps-4 pe-4 pt-2 pb-0 overflow-auto"
                  height={"270px"}
                >
                  <div className="ps-2">
                    <FormNumericTextBox
                      type="number"
                      width="286px"
                      state={inputState}
                      steppers={true}
                      format=""
                      className={"mt-2 col-4"}
                      labelForBox={"Units"}
                      label={CONSTANT.INPUT.UNITS}
                      onChangeInput={handleInputChange}
                    />
                    <div className="d-flex align-items-center mt-2 col-4 gap-1">
                      <div className="d-flex flex-column right-align">
                        <div className="d-flex align-items-baseline ">
                          <Label className="label mb-1 ">Rate</Label>
                          <Tooltip anchorElement="target" position="top">
                            <img
                              className="ms-1 cursor-pointer"
                              src={infoIcon}
                              title={rateInfoText}
                              style={{
                                height: "14px",
                                width: "14px",
                              }}
                            />
                          </Tooltip>
                        </div>
                        <FormInput
                          type="number"
                          width="286px"
                          labelShow={false}
                          state={inputState}
                          className={"col-4"}
                          label={CONSTANT.INPUT.RATE}
                          onChangeInput={handleInputChange}
                        />
                      </div>
                      <div></div>
                    </div>
                    <div className="row mt-2">
                      <div className="d-flex align-items-baseline ">
                        <Label className="label mb-1 ">Value</Label>
                        <Tooltip anchorElement="target" position="top">
                          <img
                            className="ms-1 cursor-pointer"
                            src={infoIcon}
                            title={`MPU = ${inputState[CONSTANT.INPUT.MPU]}`}
                            style={{
                              height: "14px",
                              width: "14px",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <FormInput
                        type="number"
                        width="286px"
                        labelShow={false}
                        state={inputState}
                        className={"col-4"}
                        label={CONSTANT.INPUT.VALUE}
                        onChangeInput={handleInputChange}
                      />
                    </div>
                  </div>
                </Tile>
                <FileDragDrop onFilesDrop={handleDrop} uploadRef={uploadRef}>
                  <Tile
                    heading="Document"
                    height={"370px"}
                    // width={"100%"}
                    className="m-3 ps-4 pe-4 overflow-auto"
                  >
                    {filesData[0] ? (
                      <div
                        className="mt-2"
                        // style={{
                        //   width: "100%",
                        //   margin: "auto",
                        // }}
                      >
                        <DocumentViewer
                          data={filesData}
                          onScreenComponent={true}
                          iconLess={true}
                          showTools={false}
                          onClose={() => {
                            if (!props.isView && filesData.length) {
                              setFilesData([]);
                            } else {
                              toast.error("Attached document cant be changed");
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={(e) => filePickerRef?.current?.click()}
                        className={`d-flex align-items-center cursor-pointer justify-content-center`}
                        style={{
                          height: "80%",
                        }}
                      >
                        {isFileLoading ? (
                          <div>
                            <div
                              className="spinner-border spinner"
                              role="status"
                              // style={{ marginRight: "60px" }}
                            ></div>
                          </div>
                        ) : (
                          <>
                            <div className="mt-3">
                              <p className={styles["doc-upload-text"]}>
                                {filesData[0]?.downloadURL
                                  ? "Update"
                                  : "Attach"}
                                <br /> Documents
                              </p>
                            </div>
                            <div className={styles["vertical-rule"]}></div>
                            <div
                              className={`${styles["file-upload-icon"]} d-flex align-items-center justify-content-center position-relative`}
                            >
                              <RiUpload2Fill color="#DCDAFF" size={20} />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </Tile>
                </FileDragDrop>
              </div>
            </div>
          </form>
          <input
            name="file"
            type="file"
            onChange={handleUpload}
            ref={filePickerRef}
            style={{
              visibility: "hidden",
            }}
          />
        </div>

        <div className="dialog-footer">
          <Button
            className="common-btn bgBtnSecondary border-0 me-4"
            onClick={props.setshowMatterForm}
          >
            Cancel
          </Button>
          <Button className="common-btn me-4" type="submit" form="matterForm">
            {props.isView && !props?.isDuplicate ? "Update" : "Save"}
          </Button>
        </div>

        {showPdf && (
          <Window
            style={{
              position: "absolute",
              inset: 0,
              margin: "auto",
              zIndex: 999999,
            }}
            title="View"
            onClose={() => setShowPdf(false)}
            initialWidth={"60%"}
            initialHeight={615}
          >
            <PDFViewer
              data={viewFile}
              onClose={(e) => {
                setViewFile("");
              }}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Window>
        )}
      </Dialog>

      <MatterModal
        showModal={showModal}
        message={modalMessage}
        success={modalSuccess}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default CaseActivityForm;
