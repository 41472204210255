import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Checkbox } from "@progress/kendo-react-inputs";
import { HandleGridBody } from "../../components/common/HandleGridBody.js";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import * as api from "../../api/index.js";
import {
    DateFormatCell,
    AmountFormatCell,
    CreditAmountFormatCell,
    DebitAmountFormatCell,
} from "components/common/formatter";
import { AiOutlineFile } from "react-icons/ai";
import { NominalTypeFormat } from "components/common/nominalTypeFormatter";
import {
    changeNominalToLedgerRef,
    getactiveNominals,
    getNominalsByRef,
} from "actions/nominals.action";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { getNominalBalances } from "actions/nominals.action";
import { getPostingDetails, resetFields } from "actions/postingDetails.action";
import ViewPostingDetails from "../Postings/PostingDetails.jsx";
import NominalBalances from "./NominalBalances";
import Icon from "components/Global/icon";
import styles from "./nominal.module.scss";
import { handleApiError } from "Helper/index.js";
import { toast } from "react-toastify";
import DocumentViewer from "components/common/DocumentViewer";
import InfoBox from "components/Global/InfoBox.jsx";
import { useMatch } from "react-router-dom";
import { RedirectCell } from "components/common/GridComponents.jsx";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton.jsx";

const NominalLedger = () => {
    const dispatch = useDispatch();
    const [fileData, setFileData] = useState([]);
    const [visible1, setVisible1] = useState(false);
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [isSearched, setIsSearched] = useState(false);
    const [isRefreshed, setIsRefreshed] = useState(false);
    const [transNo, settransNo] = useState("");
    const [selectedNominal, setSelectedNominal] = useState(null);
    const [activenominalRefList, setActivenominalRefList] = useState([]);
    const [showForm, setshowForm] = useState(false);
    const { currentNominalRef } = useSelector((state) => state.nominalList);
    const [list, setLists] = useState({
        data: [],
        total: 1,
    });
    const [IncludeReversals, setIncludeReversals] = useState(false);
    const handleReserved = () => {
        setIsSearched(false);
        setIsRefreshed(false);
        setUpdateData(false);
        setIncludeReversals(!IncludeReversals);
    };
    const [dataState, setDataState] = useState({
        take: 200,
        skip: 0,
    });
    useEffect(() => {
        dispatch(getactiveNominals());
    }, []);
    const { yearStartDate } = useSelector((state) => state.company.company);

    useEffect(() => {
        if (yearStartDate !== undefined) {
            let dateTemp = new Date(yearStartDate);
            setFromDate(dateTemp?.toISOString()?.split("T")[0]);
        }
    }, [yearStartDate]);
    const date = new Date();
    let yearsStartDate = new Date(
        yearStartDate === undefined ? date : yearStartDate
    );
    const [fromDate, setFromDate] = useState(
        yearsStartDate?.toISOString()?.split("T")[0]
    );
    const [fromDateSelector, setFromDateSelector] = useState(yearsStartDate);
    const onChangeFromDateSelect = (event) => {
        setFromDateSelector(event.value);
        let fromDate = null;
        let date = new Date(event.value);
        if (event.value) {
            fromDate = date.toISOString().split("T")[0];
        }
        setIsSearched(false);
        setIsRefreshed(false);
        setFromDate(fromDate);
        setUpdateData(false);
    };
    const [toDate, setToDate] = useState(date.toISOString().split("T")[0]);
    const [toDateSelector, setToDateSelector] = useState(date);
    const onChangeToDateSelect = (event) => {
        setToDateSelector(event.value);
        let toDate = null;
        let date = new Date(event.value);
        if (event.value) {
            toDate = date.toISOString().split("T")[0];
        }
        setIsSearched(false);
        setIsRefreshed(false);
        setUpdateData(false);
        setToDate(toDate);
    };
    const [updateData, setUpdateData] = useState(false);
    const [searchNominalComboRef, setSearchNominalComboRef] = useState(null);
    const [nominalRefdata, setNominalRefData] = useState();
    const { nominalList } = useSelector((state) => state);
    const [show, setShow] = useState(false);

    const createState = (skip, take) => {
        let pagerSettings = {
            pageSizes: [25, 50, 100, 200],
        };
        return {
            pageSize: take,
            pageable: pagerSettings,
        };
    };
    const [state] = useState(createState(0, 10));

    useEffect(() => {
        if (nominalList) {
            let list = nominalList.activenominals.data.map((item) => {
                const templist = item;
                templist.representation = item.nominalRef + "  -  " + item.nominalName;
                return templist;
            });
            setActivenominalRefList(list);
            setNominalRefData(list.slice());
        }
    }, [nominalList]);

    const filterData = (filter) => {
        const datas = activenominalRefList.slice();
        return filterBy(datas, filter);
    };

    const onFilterChange = (event) => {
        setNominalRefData(filterData(event.filter));
    };
    const nominalGetter = (id) => {
        let value = null;
        nominalList?.activenominals?.data?.map((item) => {
            if (item.nominalRef === id) {
                value = item;
            }
        });
        return value;
    };
    const changeURLNominalRef = () => {
        const value = nominalGetter(currentNominalRef);
        changeNominalRef({ value: value });
    };
    const changeNominalRef = (event) => {
        setIsSearched(true);
        setSearchNominalComboRef(event?.value);
        let searchedVal = null;
        if (event.value === null) {
            searchedVal = null;
        } else {
            searchedVal = event?.value?.nominalRef;
        }

        setSelectedNominal(event.value);
        setSearchValue(searchedVal);
        if (event.value === null) {
            setLists([]);
            return;
        }
        dispatch(getNominalBalances(event?.value?.nominalRef, fromDate, toDate));
    };

    const handleAction = (transNo) => {
        // dispatch(getPostingDetails(transNo));
        settransNo(transNo);
        setVisible(!visible);
        setshowForm(true);
    };

    const handleActionRef = (e, transNo) => {
        if (!transNo) return toast("Transaction number required");
        // dispatch(getPostingDetails(transNo));
        settransNo(transNo);
        setVisible(!visible);
        setshowForm(true);
    };

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };
    const dataReceived = (list) => {
        if (list.data) {
            if (list.total == 0) {
                list["total"] = 1;
            }
            setLists(list);
        }
    };

    const RefCell = (e) => {
        return (
            <td
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                    handleActionRef(event, e.dataItem.transactionNo);
                }}
            >
                <u>{e.dataItem.reference}</u>
            </td>
        );
    };

    const Redirect = (props) => (
        <RedirectCell
            handleClick={() => handleAction(props.dataItem.transactionNo)}
        />
    );

    const refreshData = () => {
        if (searchValue) {
            let pageData = {
                dataState: {
                    take: 200,
                    skip: 0,
                },
            };
            dataStateChange(pageData);
            setSearchValue(searchValue);
            setIsSearched(true);
            setIsRefreshed(true);
            // dispatch(getNominalBalances(searchValue, fromDate, toDate));
        }
    };

    const postingDoc = async (transNo) => {
        setShow(!show);
        try {
            const { data } = await api.getPostingDocuments(transNo);
            setFileData(data.data);

            setVisible1(true);
        } catch (error) {
            handleApiError(error);
        }
    };

    const DocumentCounterCell = (e) => {
        return (
            <td>
                <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                    {e.dataItem.documentCount !== null && (
                        <>
                            <Button
                                onClick={() => postingDoc(e.dataItem.transactionNo)}
                                className="border-0"
                            >
                                <AiOutlineFile color="blue" size={20} />
                            </Button>
                        </>
                    )}
                    <span>
                        {e.dataItem.documentCount === null ? "" : e.dataItem.documentCount}
                    </span>
                </div>
            </td>
        );
    };

    useEffect(() => {
        if (currentNominalRef) {
            changeURLNominalRef();
            dispatch(changeNominalToLedgerRef(undefined));
        }
    }, [currentNominalRef]);

    const grid = (
        <div className="pr mt-3">
            <Grid
                {...dataState}
                data={list}
                onDataStateChange={dataStateChange}
                className={`${styles.nominalledger_gridheight}  grid-fs13--`}
                pageable={state.pageable}
                pageSize={state.pageSize}
            >
                <GridColumn
                    field="date"
                    title="Date"
                    width="100px"
                    cell={DateFormatCell}
                />
                <GridColumn
                    className="grid-standout-field"
                    field="postingType"
                    title="Type"
                    width="60px"
                />
                <GridColumn
                    field="reference"
                    title="Ref"
                    cell={RefCell}
                    width="120px"
                />
                <GridColumn field="details" title="Details" />
                <GridColumn field="transactionPeriod" title="Period" width="80px" />

                <GridColumn
                    field="grossAmount"
                    title="Debit"
                    className="debitCol"
                    cell={DebitAmountFormatCell}
                    width="120px"
                    headerClassName="grid-header-center"
                />
                <GridColumn
                    field="grossAmount"
                    title="Credit"
                    className="creditCol"
                    cell={CreditAmountFormatCell}
                    width="120px"
                    headerClassName="grid-header-center"
                />

                <GridColumn
                    field="balance"
                    title="Balance"
                    className="balanceCol balanceTd"
                    cell={AmountFormatCell}
                    width="140px"
                    headerClassName="grid-header-center"
                />
                <GridColumn
                    field="documentCount"
                    title="Doc"
                    width="80px"
                    cell={DocumentCounterCell}
                    headerClassName="grid-header-center"
                />
                <GridColumn width="50px" cell={Redirect} />
            </Grid>

            {(isSearched && searchValue) || (isRefreshed && searchValue) ? (
                <HandleGridBody
                    dataState={dataState}
                    onDataReceived={dataReceived}
                    searchValue={(isSearched && searchValue) || null}
                    isSearched={isSearched}
                    isRefreshed={isRefreshed}
                    IncludeReversals={IncludeReversals}
                    FromDate={fromDate}
                    ToDate={toDate}
                    currentComponent="nominalLedger"
                />
            ) : (
                // <HandleGridBody
                //   dataState={dataState}
                //   onDataReceived={dataReceived}
                //   currentComponent="nominalLedger"
                // />
                <></>
            )}
        </div>
    );

    return (
        <div className="page">
            {visible1 && (
                <DocumentViewer data={fileData} onClose={() => setVisible1(false)} />
            )}

            <div className="d-flex w-100">
                <span className="mt-2 me-3">Nominal Ref</span>
                <div className="ms-0">
                    {nominalRefdata && (
                        <ComboBox
                            iconClassName="wa"
                            icon={<Icon icon="arrow-down" />}
                            data={nominalRefdata}
                            textField={"representation"}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            placeholder={"Search Nominal Ref"}
                            value={searchNominalComboRef}
                            onChange={changeNominalRef}
                            style={{ width: "300px" }}
                        />
                    )}
                    <InfoBox
                        className="mt-2 w-100"
                        text={
                            selectedNominal !== null &&
                            `${NominalTypeFormat(
                                selectedNominal?.type
                            )} - ${NominalTypeFormat(selectedNominal?.subType)}`
                        }
                        title="Type"
                    />
                </div>
                <Button
                    className="common-btn ms-3"
                    icon="refresh"
                    onClick={refreshData}
                >
                    Refresh
                </Button>

                <div className="float-end ms-auto align-items-end">
                    <NominalBalances
                        type={"NominalLedger"}
                        nominal={searchNominalComboRef}
                    />
                </div>
            </div>
            {showForm && (
                <ViewPostingDetails
                    reloadData={refreshData}
                    transNo={transNo}
                    cancelEdit={() => {
                        setshowForm(false);
                        dispatch(resetFields());
                    }}
                />
            )}
            <div className="move-up">
                <div className="nominal-form-to-parent-row">
                    <div className="labels-containers">
                        <Label editorId={"dp"}>From</Label>
                        <div className="label-to">
                            <Label editorId="to">To</Label>
                        </div>
                    </div>
                </div>
                <div className="nominal-form-to-row">
                    <div className="col-3 date-form-to-wrapper">
                        <div className="from">
                            <DatePicker
                                id="dp"
                                className="date-picker"
                                value={fromDateSelector || null}
                                onChange={onChangeFromDateSelect}
                                dateInput={CustomDateInput}
                                toggleButton={CalenderCustomToggleButton}
                                defaultValue={fromDateSelector || null}
                                placeholder="Select from date"
                            />
                        </div>
                        <div className="divider"></div>
                        <div className="to">
                            <DatePicker
                                id="to"
                                className="date-picker"
                                value={toDateSelector || null}
                                onChange={onChangeToDateSelect}
                                dateInput={CustomDateInput}
                                toggleButton={CalenderCustomToggleButton}
                                defaultValue={toDateSelector || null}
                                placeholder="Select to date"
                            />
                        </div>
                    </div>

                    <div className="show-reversed">
                        <Checkbox
                            defaultChecked={false}
                            onClick={handleReserved}
                            label={"Show Reversed"}
                        />
                    </div>
                </div>
            </div>

            <div>{grid}</div>
        </div>
    );
};
export default NominalLedger;
