import {
  START_LOADING,
  STOP_LOADING,
  ERROR,
  SUCCESS,
  CREATE_TIME,
  TIME_ERROR,
  RESET_TIME_ERROR,
  GET_RECORDED_TIME,
  GET_RECORDED_TIME_FOR_MATTER,
  GET_DASHBOARD_TIME_TARGET,
} from "../types/index";
import * as api from "../api/index.js";
import { handleApiError } from "Helper";
import { toast } from "react-toastify";

export const addTimeFunctionality = (datas, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const data = await api.createTimeFunctionality(datas);
    dispatch({ type: CREATE_TIME, payload: data.data });
    toast.success("Time Target added Sucessfully");
    dispatch({ type: STOP_LOADING });
    dispatch({ type: SUCCESS, payload: data.message });
    if (onSuccess) onSuccess();
  } catch (error) {
    handleApiError(error);
    dispatch({ type: TIME_ERROR, payload: error });
    toast.error("Error in adding Time Target");
  }
};

export const getRecordedTime =
  (feeEarner, status, fD, tD, matterId, page, limit) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const datas = await api.getRecordedTime(
        feeEarner,
        status,
        fD,
        tD,
        matterId,
        page,
        limit
      );
      dispatch({ type: GET_RECORDED_TIME, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };

export const getRecordedTimeForMatter =
  (matterId, status, fD, tD, page, limit, sort) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      let parameter;
      if (sort === undefined) {
        parameter = "matterRef";
      } else {
        if (sort.dir === "asc") {
          parameter = sort.field;
        } else if (sort.dir === "desc") {
          parameter = sort.field + " desc";
        }
      }
      const datas = await api.getRecordedTimeForMatter(
        matterId,
        status,
        fD,
        tD,
        page,
        limit,
        parameter
      );
      dispatch({
        type: GET_RECORDED_TIME_FOR_MATTER,
        id: matterId,
        payload: datas.data,
      });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };

export const clearTimeError = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    dispatch({ type: RESET_TIME_ERROR });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const getDashboardTimeTargetData =
  (teamId, feeEarnerRef, branchId, fromDate, toDate) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const datas = await api.getDashboardTimeTargetData(
        teamId,
        feeEarnerRef,
        branchId,
        fromDate,
        toDate
      );
      dispatch({ type: GET_DASHBOARD_TIME_TARGET, payload: datas.data });
      dispatch({ type: STOP_LOADING });
      dispatch({ type: SUCCESS, payload: datas.message });
    } catch (error) {
      handleApiError(error);
    }
  };
