import React, { useEffect } from "react";
import { Tile } from "components/UIComponents/Tile";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import styles from "./DocumentList.module.scss";
import { getClientDocumentsList, getDocument } from "api";
import { DateFormatCell } from "components/common/formatter";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import { useState } from "react";
import ClientDocumentModal from "./AddEditDocument";
import fileIcon from "components/common/fileIcon";
import { FileDragDrop } from "pages/CaseFile/CaseActivities/CaseActivitiesPage";
import DocumentViewer from "components/common/DocumentViewer";

export const DocumentList = ({ clientId }) => {
  const [list, setLists] = React.useState({ data: [], total: 50 });
  const [showDocRegister, setShowDocRegister] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [viewId, setViewId] = useState();
  const uploadRef = React.createRef();
  const [showDocument, setShowDocument] = useState(false);
  const [documentViewerData, setDocumentViewerData] = useState([]);
  const getClientDocsData = async () => {
    try {
      const data = await getClientDocumentsList(clientId?.clientId);
      setLists({ data: data?.data?.data, total: data?.data?.total });
    } catch (error) {}
  };

  const [dataState, setDataState] = React.useState({
    take: 100,
    skip: 0,
    sort: [
      {
        field: "documentDate",
        dir: "asc",
      },
    ],
  });

  const showDocumentFile = async (id) => {
    const { data } = await getDocument(id);
    setDocumentViewerData([data.data]);
    setShowDocument(true);
  };

  const ViewDocs = (props) => {
    return (
      <td>
        <Button
          onClick={() => {
            setViewId(props.dataItem);
            setShowDocRegister(true);
          }}
          className="button-small "
        >
          View
        </Button>
      </td>
    );
  };

  const DocumentCell = (e) => {
    const { documentId, documentName } = e.dataItem;
    return documentId ? (
      <td className="p-1" {...e} onClick={() => showDocumentFile(documentId)}>
        <div role="button" className="d-flex">
          {fileIcon(documentName)}

          <span className="ps-2 fs-sm d-lg-none d-xl-block">
            {documentName?.length > 20
              ? documentName.substring(0, 20)
              : documentName}
          </span>

          <span className="ps-2 fs-sm d-xl-none d-lg-block">
            {documentName?.split(".").pop()}
          </span>
        </div>
      </td>
    ) : (
      <td></td>
    );
  };

  const dataStateChange = (e) => {
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState?.sort?.[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState?.sort?.[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
  };

  const handleDrop = (event) => {
    setDroppedFiles([...event]);
    setShowDocRegister(true);
  };

  useEffect(() => {
    if (clientId) {
      getClientDocsData();
    }
  }, []);

  return (
    <div className="container mt-4">
      <Tile
        heading="Document Register"
        className="mb-2 py-2 px-4 overflow-auto"
        height={"580px"}
        headingItems={
          <div>
            <Button
              className="button-small bgBtnSecondary fw-bold me-1"
              onClick={() => {
                setViewId(null);
                setShowDocRegister(true);
              }}
            >
              Add Document
            </Button>
          </div>
        }
      >
        <FileDragDrop onFilesDrop={handleDrop} uploadRef={uploadRef}>
          <Grid
            data={process(list.data, dataState)}
            onDataStateChange={dataStateChange}
            className={`fs-md pb-1 ${styles.gridheight}`}
            sortable={true}
            resizable={true}
            {...dataState}
          >
            <GridColumn
              field="date"
              title="Date"
              width="120px"
              cell={DateFormatCell}
              className="gridBoldField ps-4"
              headerClassName="ps-4"
            />
            <GridColumn field="details" title="Details" />
            <GridColumn field="feeEarnerRef" title="Fee Earner" width="200px" />
            <GridColumn
              field="documentId"
              title="Document"
              cell={DocumentCell}
              width="180px"
            />
            <GridColumn field="" title="  " width="78px" cell={ViewDocs} />
          </Grid>
        </FileDragDrop>
      </Tile>

      {showDocRegister && (
        <ClientDocumentModal
          setshowMatterForm={() => {
            setViewId(null);
            setShowDocRegister(false);
            if (droppedFiles.length) setDroppedFiles([]);
          }}
          droppedFiles={droppedFiles}
          docId={viewId}
          updateData={() => {
            setViewId(null);
            setShowDocRegister(false);
            if (droppedFiles.length) setDroppedFiles([]);
            if (clientId) {
              getClientDocsData();
            }
          }}
          clientId={clientId}
        />
      )}

      {showDocument && (
        <DocumentViewer
          data={documentViewerData}
          onClose={() => {
            setShowDocument(false);
            setDocumentViewerData([]);
          }}
        />
      )}
    </div>
  );
};
