import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DocumentsUpload } from "../Postings/DcoumentsUpload";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { clearFilesDispPayment } from "actions/files.action";
import * as api from "../../api/index";
import { useUploadForm } from "components/fileUploadHook";
import { onFileUpload } from "Services/fileDataService";
import { usePreComponentCalls } from "components/layout/PreComponentLoad";
import PaySupplierGrid from "./PaySupplierGrid";
import SearchComboBox from "components/common/SearchComboBox";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import { toast } from "react-toastify";
import Modalposting from "components/BatchPosting/PostingsModal";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { TransactionPeriod } from "components/common/TransactionPeriod";

const typeData = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Disbursements",
        value: "disbursements",
    },
    {
        label: "Purchase Invoices",
        value: "invoices",
    },
];

const PaySupplier = ({ is_type }) => {
    const dispatch = useDispatch();
    const date = new Date();
    const { handleCalls } = usePreComponentCalls();
    useEffect(() => {
        handleCalls("Pay Disbursement");
    }, []);
    const [details, setDetails] = useState("");
    const [reference, setReference] = useState("");
    const [data, setData] = useState([]);
    const [payee, setPayee] = useState("");
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("all");
    const [dateselector, setdateselector] = useState(date);
    const [supplierRef, setSupplierRef] = useState();
    const [allocation, setAllocation] = useState(null);
    const [modalvalue, setmodalvalue] = useState();
    const [initialData, setInitialData] = useState({
        date: new Date(Date.now()),
    });

    const filesCurrentData = useSelector(
        (state) => state.filesDownload.disbPaymentFiles
    );

    const getSupplierData = async (supplierRef) => {
        if (supplierRef) {
            let transactiontype = type;
            if (type === "all") transactiontype = undefined;
            const datas = await api.getPaySupplierList(
                supplierRef,
                transactiontype,
                is_type
            );
            datas?.data?.data.forEach((element) => {
                element.inEdit = true;
                element.add = false;
                element.amount = 0;
            });
            setData(datas?.data?.data);
        } else {
            setData([]);
        }
    };

    const onChangeSupplier = async (event) => {
        try {
            getSupplierData(event?.value?.supplierRef);
            setSupplierRef(event?.value?.supplierRef);
            setInitialData({
                ...initialData,
                supplierRef: event?.value,
            });
        } catch (error) {
            console.error(error);
        }
    };
    const onChangedateselect = (event) => {
        setdateselector(event.value);
    };

    useEffect(() => {
        if (supplierRef) getSupplierData(supplierRef);
    }, [type]);

    async function Submithandler(value) {
        const data = [
            {
                timeId: 0,
                postingType: "SUP",
                reference: reference,
                supplierRef: value?.supplierRef?.supplierRef,
                feeEarnerRef: value?.feeEarner?.userRef,
                date: dateselector,
                netAmount: amount,
                transactionPeriod: 202201,
                currencyCode: "GBP",
                details: details,
                reference: reference,
                Payee: payee,
                OfficeBank: value?.officeBank?.nominalRef,
            },
        ];

        if (allocation !== null) {
            data[0].allocations = allocation;
        }
        if (filesCurrentData[0] !== undefined) {
            const docsId = [];
            filesCurrentData.map((item) => {
                docsId.push(item.fileID);
            });
            data[0].DocumentFiles = docsId;
        }
        let totalAllocatedAmount = 0;
        allocation.forEach((item) => {
            totalAllocatedAmount += item.amount;
        });
        if (totalAllocatedAmount !== amount) {
            toast.error("Allocated amount should be equal to net amount");
            return;
        }
        setLoading(true);
        try {
            const response = await api.createposting(data);
            setmodalvalue({
                code: response?.status,
                data: response?.data,
            });
        } catch (error) {
            setmodalvalue({
                code: error?.response?.status,
                data: error?.response.data,
            });
        }
        setLoading(false);
    }
    const reset = () => {
        document.getElementById("reset-button-supplier").click();
        setDetails("");
        setAmount(0);
        setPayee("");
        setReference("");
        getSupplierData(supplierRef);
        setmodalvalue();
        dispatch(clearFilesDispPayment());
    };

    const { uploadForm, progress } = useUploadForm();

    const [filesData, setFilesData] = React.useState([]);

    const handleUpload = async (e) => {
        let data = onFileUpload(e);
        setFilesData([...filesData, data]);
        const dataChoosen = await uploadForm(data, e.target.files[0]);
        data.fileID = dataChoosen.data.data.fileID;
    };
    const onFileRemove = (fileId) => {
        setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
    };

    return (
        <div
            className="container-fluid ps-0 pt-0 bg"
            style={{ height: "calc(100vh - 100px)" }}
        >
            <div className="row h-100 g-0">
                <div
                    className="bgForm ms-2 pt-4 d-flex justify-content-center align-item-center"
                    style={{
                        width: "380px",
                        background: "#F8F8F8",
                        overflow: "auto",
                        maxHeight: "calc(100vh - 129px)",
                    }}
                >
                    <Form
                        ignoreModified={true}
                        onSubmit={Submithandler}
                        initialValues={initialData}
                        key={JSON.stringify(initialData)}
                        render={(formRenderProps) => {
                            return (
                                <FormElement
                                    name="disbform"
                                    id="disbform"
                                    style={{
                                        width: "85%",
                                    }}
                                >
                                    <fieldset>
                                        {formRenderProps.visited &&
                                            formRenderProps.errors &&
                                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                                <div className={"k-messagebox k-messagebox-error"}>
                                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                                </div>
                                            )}
                                        <div className="mb-3">
                                            <SearchComboBox
                                                name="supplierRef"
                                                cbType={is_type ? CONSTANT.CR : CONSTANT.SR}
                                                controlled={true}
                                                onChangeFunction={onChangeSupplier}
                                                width="auto"
                                                boxShadow={true}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="label">Date</Label>
                                            <div
                                                style={{
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <DatePicker
                                                    className="datepicker px-1"
                                                    value={dateselector}
                                                    onChange={onChangedateselect}
                                                    dateInput={CustomDateInput}
                                                    toggleButton={CalenderCustomToggleButton}
                                                    defaultValue={dateselector}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <SearchComboBox
                                                name="officeBank"
                                                cbType={CONSTANT.OB}
                                                width="auto"
                                                boxShadow={true}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="label">Reference</Label>
                                            <div
                                                style={{
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <Input
                                                    width={200}
                                                    value={reference}
                                                    onChange={(e) => {
                                                        setReference(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 right-align">
                                            <Label className="label">Amount</Label>
                                            <div
                                                style={{
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <NumericTextBox
                                                    width={300}
                                                    style={{ textAlign: "right" }}
                                                    value={amount}
                                                    onChange={(e) => {
                                                        setAmount(e.target.value);
                                                    }}
                                                    placeholder="0.00"
                                                    spinners={false}
                                                    format="n2"
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <SearchComboBox
                                                name="feeEarner"
                                                cbType={CONSTANT.FE}
                                                width="auto"
                                                boxShadow={true}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="label">Payee</Label>
                                            <div
                                                style={{
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <Input
                                                    name="payee"
                                                    width={200}
                                                    value={payee}
                                                    onChange={(e) => {
                                                        setPayee(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="label">Details</Label>
                                            <div
                                                style={{
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <TextArea
                                                    rows={4}
                                                    placeholder="Enter Details"
                                                    value={details}
                                                    onChange={(e) => {
                                                        setDetails(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <DocumentsUpload
                                                progress={progress}
                                                filesData={filesData}
                                                onFileRemove={onFileRemove}
                                                onUpload={handleUpload}
                                                onError={() => {
                                                    const newFiles = filesData.splice(
                                                        filesData.length,
                                                        1
                                                    );
                                                    setFilesData([...newFiles]);
                                                }}
                                                type={"DUP"}
                                            />
                                        </div>
                                        <button
                                            id="reset-button-supplier"
                                            type="reset"
                                            style={{ visibility: "hidden" }}
                                            onClick={() => formRenderProps.onFormReset()}
                                        ></button>
                                        <button
                                            id="submit-button-supplier"
                                            type="button"
                                            style={{ visibility: "hidden" }}
                                            onClick={() => {
                                                formRenderProps.onSubmit();
                                            }}
                                        ></button>
                                    </fieldset>
                                </FormElement>
                            );
                        }}
                    />
                </div>

                <div className="col" style={{ overflowX: "auto" }}>
                    <TransactionPeriod />
                    <div className=" ms-3 me-3 mt-3 d-flex flex-column align-items-end radio">
                        <RadioGroup
                            className="d-flex float-end mb-3 me-3"
                            data={typeData}
                            value={type}
                            onChange={(e) => setType(e.value)}
                        />
                        <PaySupplierGrid
                            updateAllocations={(value) => {
                                setAllocation(value);
                            }}
                            setAmount={() => { }}
                            type={type}
                            data={data}
                        />
                        <div className="buttonstyle">
                            <Button
                                form="disbform"
                                className="common-btn"
                                onClick={() =>
                                    document.getElementById("submit-button-supplier").click()
                                }
                                type="button"
                                disabled={loading}
                            >
                                Post
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modalposting
                data={modalvalue}
                closeModal={() => {
                    setmodalvalue();
                }}
                reset={reset}
            ></Modalposting>
        </div>
    );
};
export default PaySupplier;
