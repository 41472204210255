import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState, useEffect } from "react";
import Download from "../../assets/download-icon.png";
import Printer from "../../assets/printer-icon.png";
import { toast } from "react-toastify";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  AmountFormatCell,
  DateFormatCell,
  AmountFormat,
} from "components/common/formatter";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { getPostingdetails, getDocument } from "api";
import { reversePosting } from "api/accountsAPI";
import LoadingScreen from "components/common/Loading";
import { Tile } from "components/UIComponents/Tile";
import documentIcon from "../../assets/fileIcons/Document.svg";
import DocumentViewer from "components/common/DocumentViewer";
import EditPosting from "./EditPosting";
import { formatDate } from "@progress/kendo-intl";
import DataList from "components/MatterDetails/DataList";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentTransactionperiods,
  getTransactionperiods,
} from "../../actions/transactionperiod.action.js";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const ViewPosting = (props) => {
  const [mainData, setMainData] = useState();
  const [postingInfo, setPostingInfo] = useState({});
  const [doubleEntries, setDoubleEntries] = useState([]);
  const [postingName, setPostingName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [documentViewerData, setDocumentViewerData] = useState([]);
  const [showEditPosting, setShowEditPosting] = useState(false);
  const [showReversePosting, setShowReversePosting] = useState(false);
  const [reason, setReason] = useState("");
  const [reversalDate, setReversalDate] = useState(new Date());
  const [allocations, setAllocations] = useState([]);
  const [selected, setSelected] = useState(0);

  const dispatch = useDispatch();

  const transactionsData = useSelector(
    (state) => state.transactionperiods.transactionList
  );
  const [sessionPeriod, setSessionPeriod] = useState(
    useSelector((state) => state.transactionperiods.currentPeriods)
  );

  useEffect(() => {
    getPostingDetails();
    dispatch(getCurrentTransactionperiods());
    dispatch(getTransactionperiods());
  }, []);

  const getPostingDetails = async () => {
    try {
      setIsLoading(true);
      const data = await getPostingdetails(props.transNo);
      setMainData(data?.data?.data);
      setAllocations(data?.data?.data?.allocations);
      setDoubleEntries(data?.data?.data?.doubleEntries);
      setPostingName(data?.data?.data?.postingName);
      setPostingInfo(data?.data?.data?.postingInfo);
    } catch (error) {
      props.cancelEdit();
      toast.error("Error occurred while fetching posting details.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const data = {
        reversalDate: reversalDate,
        reverseReason: reason,
        reversalPeriod: sessionPeriod?.period,
      };
      const response = await reversePosting(props.transNo, data);
      if (response.status === 200) {
        toast.success("Posting reversed successfully");
        setShowReversePosting(false);
        props.cancelEdit();
        props.reloadData();
      }
    } catch (error) {
      toast.error("Error occurred while reversing posting.");
    }
  };

  const showDocumentFile = async (id) => {
    const { data } = await getDocument(id);
    setDocumentViewerData([data.data]);
    setShowDocument(true);
  };

  const renderDataList = (
    text,
    value,
    keyStyles = {},
    valueClass = "mb-2",
    className
  ) => (
    <DataList
      text={text}
      value={value}
      keyClass="mb-2"
      valueClass={valueClass}
      width="w-100"
      className={className}
      keyStyles={{ minWidth: "100px", ...keyStyles }}
    />
  );

  const renderTile = () => (
    <Tile
      className="mt-3 py-1 px-lg-4 px-3 me-2 w-75 pb-3"
      heading="Posting Details"
    >
      <div>
        {renderDataList(
          "Matter:",
          mainData?.matterRef || "",
          { minWidth: "100px" },
          "mb-3"
        )}
      </div>
      <div className="d-flex justify-content-between me-1">
        <div>
          {renderDataList(
            "Date:",
            formatDate(new Date(postingInfo?.date), "d", "en-GB")
          )}
        </div>
        <div>
          {renderDataList(
            "Net Amount:",
            AmountFormat(postingInfo?.netAmount),
            {
              minWidth: "115px",
            },
            "text-end"
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between me-1">
        <div>{renderDataList("Reference:", postingInfo?.reference)}</div>
        <div>
          {renderDataList(
            "VAT Amount:",
            AmountFormat(postingInfo?.vatAmount),
            {
              minWidth: "115px",
            },
            "text-end"
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between me-1">
        <div>{renderDataList("Fee Earner:", postingInfo?.feeEarnerRef)}</div>
        <div>
          {renderDataList(
            "VAT Code:",
            `${postingInfo.vatCode} - ${postingInfo.vatRate} %`,
            { minWidth: "115px" }
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end me-1">
        <div>
          {renderDataList(
            "Gross Amount:",
            AmountFormat(postingInfo.netAmount + postingInfo.vatAmount),
            { minWidth: "115px" },
            "text-end"
          )}
        </div>
      </div>
      <div className="d-flex me-1">
        <div className="w-100">
          {renderDataList("Details:", postingInfo.details)}
        </div>
      </div>
    </Tile>
  );

  const renderTransactionsTile = () => (
    <Tile className="py-1 px-lg-4 px-3 mt-3" heading="Transactions">
      <div className="d-flex justify-content-center align-items-start flex-column mt-2">
        <div className="my-1">
          {renderDataList(
            "Transaction Period:",
            postingInfo.transactionPeriod,
            {
              minWidth: "145px",
            }
          )}
        </div>
        <div className="my-1">
          {renderDataList("Transaction No:", props.transNo, {
            minWidth: "145px",
          })}
        </div>
        <div className="my-1">
          {renderDataList("Added By:", mainData?.addedBy, {
            minWidth: "145px",
          })}
        </div>
        <div className="my-1">
          {renderDataList(
            "Date Added:",
            formatDate(new Date(mainData?.dateAdded), "d", "en-GB") || "",
            { minWidth: "145px" }
          )}
        </div>
        {mainData?.documents?.length > 0 && (
          <div className="mt-2">
            {mainData.documents.map((doc, index) => (
              <div
                key={index}
                className="d-flex align-items-center my-1 cursor-pointer"
                onClick={() => showDocumentFile(doc)}
              >
                <img src={documentIcon} alt="Document" width={18} height={20} />
                <p
                  style={{
                    color: "#1D3F6E",
                    textDecoration: "underline",
                    backgroundColor: "#F8F8F8",
                  }}
                  className="fs-md mb-0 ms-2"
                >
                  Document {index + 1} Attached
                </p>
              </div>
            ))}
          </div>
        )}
        {showDocument && (
          <DocumentViewer
            onClose={() => setShowDocument(false)}
            data={documentViewerData}
            hideToolbar={true}
          />
        )}
      </div>
    </Tile>
  );
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  const renderDoubleEntryTab = () => (
    <TabStrip
      keepTabsMounted={true}
      selected={selected}
      onSelect={handleSelect}
      className="mt-2 mb-1 pagetab w-100"
    >
      {["Double Entry"].map((item, index) => (
        <TabStripTab key={index} title={<PageTabItemTitle title={item} />}>
          {item === "Double Entry" && (
            <div className="mt-2">
              <Grid
                data={doubleEntries}
                style={{ maxHeight: "40vh", overflow: "auto" }}
                className="grid-fs13"
              >
                <GridColumn
                  field="date"
                  title="Date"
                  width="100px"
                  cell={DateFormatCell}
                />

                <GridColumn field="nominalRef" title="Nominal" width="100px" />

                <GridColumn field="details" title="Details" />

                <GridColumn
                  field="amount"
                  title="Amount"
                  cell={AmountFormatCell}
                  width="120px"
                  className="pe-3"
                />

                <GridColumn
                  title="Reconciled"
                  field="bankRecId"
                  width="100px"
                />
              </Grid>
            </div>
          )}
        </TabStripTab>
      ))}

      {["Allocations"].map((item, index) => (
        <TabStripTab key={index} title={<PageTabItemTitle title={item} />}>
          {item === "Allocations" && (
            <div className="mt-2">
              <Grid
                data={allocations}
                style={{ maxHeight: "40vh", overflow: "auto" }}
                className="grid-fs13"
              >
                <GridColumn field="postingType" title="Type" width="120px" />
                <GridColumn
                  field="date"
                  title="Date"
                  width="120px"
                  cell={DateFormatCell}
                />
                <GridColumn title="Reference" field="reference" width="150px" />
                <GridColumn field="details" title="Details" />
                <GridColumn
                  field="amount"
                  title="Amount"
                  cell={AmountFormatCell}
                  width="120px"
                />
              </Grid>
            </div>
          )}
        </TabStripTab>
      ))}
    </TabStrip>
  );

  const renderReversePostingDialog = () => (
    <Dialog
      title="Reverse Posting"
      onClose={() => setShowReversePosting(false)}
    >
      <Tile className="py-3 px-4 mt-3 mx-4">
        <div className="w-100 mb-2">
          <span className="fw-semibold ">
            {`${postingInfo.postingType} - ${postingName}`}
          </span>
        </div>
        <div className="d-flex">
          <div
            style={{
              width: "60%",
            }}
          >
            {renderDataList(
              "Date:",
              formatDate(new Date(postingInfo?.date), "d", "en-GB")
            )}
          </div>
          <div
            className=""
            style={{
              width: "40%",
            }}
          >
            {renderDataList("Net:", AmountFormat(postingInfo?.netAmount), {})}
          </div>
        </div>
        <div className="d-flex">
          <div
            style={{
              width: "60%",
            }}
          >
            {renderDataList("Reference:", postingInfo?.reference)}
          </div>
          <div
            style={{
              width: "40%",
            }}
          >
            {renderDataList("VAT:", AmountFormat(postingInfo?.vatAmount), {})}
          </div>
        </div>

        <div className="d-flex">
          <div
            style={{
              width: "60%",
            }}
          >
            {renderDataList("Details:", postingInfo.details)}
          </div>
          <div
            style={{
              width: "40%",
            }}
          >
            {renderDataList(
              "Gross:",
              AmountFormat(postingInfo.netAmount + postingInfo.vatAmount)
            )}
          </div>
        </div>
      </Tile>
      <Tile className="py-3 px-4 px-4 mt-3 mb-2 mx-4">
        <div className="d-flex flex-column">
          <Label className="mb-1">Reversal Date</Label>
          <DatePicker
            className="date-picker px-1"
            onChange={(e) => setReversalDate(e.target.value)}
            width="265px"
            value={reversalDate}
          />
        </div>
        <div className="mt-3 d-flex flex-column">
          <Label className="mb-1">Reversal Period</Label>
          <ComboBox
            data={transactionsData.filter((item) => !item.closed)}
            textField="period"
            filterable={true}
            className="ps-2"
            iconClassName="wa"
            placeholder="Session Period"
            style={{ width: "265px" }}
            value={sessionPeriod}
            onChange={(e) => {
              if (e.value === null) return;
              setSessionPeriod(e.value);
            }}
          />
        </div>
        <div className="mt-3 d-flex flex-column">
          <Label className="mb-1">Reason</Label>
          <TextArea
            className="w-100"
            placeholder="Write reason for reversal"
            style={{ height: "75px", maxWidth: "100%" }}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      </Tile>
      <DialogActionsBar>
        <div className="dialog-footer w-100">
          <Button
            className="common-btn bgBtnSecondary border-0 me-3"
            onClick={() => setShowReversePosting(false)}
          >
            Close
          </Button>
          <Button className="common-btn border-0 me-5" onClick={handleSubmit}>
            Reverse
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );

  return (
    <div>
      <Dialog
        title="Posting Details"
        onClose={props.cancelEdit}
        className="dialog-lg"
      >
        <div>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <div className="px-lg-5 px-4">
              <div
                className="fs-6 fw-bold d-flex align-items-center justify-content-between mt-3 border rounded px-4 py-1"
                style={{
                  backgroundColor: "#F8F8F8",
                  borderColor: "#E6E6E6",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div>
                  <span>
                    {postingInfo.postingType} - {postingName}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="border px-2 py-1 rounded me-2">
                    <img
                      className="cursor-pointer"
                      height={16}
                      width={16}
                      src={Printer}
                      alt="Print"
                    />
                  </div>
                  <div className="border px-2 py-1 rounded me-2">
                    <img
                      className="cursor-pointer"
                      height={16}
                      width={16}
                      src={Download}
                      alt="Download"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                {renderTile()}
                {renderTransactionsTile()}
              </div>
              <Tile className="py-3 px-4 mt-3">{renderDoubleEntryTab()}</Tile>
            </div>
          )}
        </div>
        <DialogActionsBar>
          <div className="dialog-footer w-100 justify-content-between">
            <div>
              <Button
                className="common-btn border-0 ms-5"
                onClick={() => setShowReversePosting(true)}
              >
                Reverse Posting
              </Button>
            </div>
            <div>
              <Button
                className="common-btn bgBtnSecondary border-0 me-3"
                onClick={props.cancelEdit}
              >
                Close
              </Button>
              <Button
                className="common-btn border-0 me-5"
                onClick={() => setShowEditPosting(true)}
              >
                Edit
              </Button>
            </div>
          </div>
        </DialogActionsBar>
      </Dialog>
      {showEditPosting && (
        <EditPosting
          reloadPosting={props.reloadData}
          cancelViewPosting={props.cancelEdit}
          postingInfo={postingInfo}
          doubleEntries={doubleEntries}
          postingName={postingName}
          transNo={props.transNo}
          cancelEdit={() => setShowEditPosting(false)}
        />
      )}
      {showReversePosting && renderReversePostingDialog()}
    </div>
  );
};

export default ViewPosting;
