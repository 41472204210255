import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import styles from "../../pages/Time/time.module.scss";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  AmountFormatCell,
  BooleanCell,
  DateFormatCell,
} from "components/common/formatter";
import AddTime from "pages/Time/AddTime";
import { HandleGridBody } from "components/common/HandleGridBody";
import { Popup } from "@progress/kendo-react-popup";
import FilterComponent from "../CaseFiles/FilterComponent";
import { FilterComponent as GeneraLFilters } from "components/common/FiltersComponent";
import { getAccessRightsData } from "api";
import { Skeleton } from "@progress/kendo-react-indicators";
import MattersCombobox from "pages/Postings/MattersComboBox";
import { Label } from "@progress/kendo-react-labels";

const statusData = [
  { key: "All", value: "" },
  { key: "Billed", value: true },
  { key: "UnBilled", value: false },
];

const CenterTextHeaderCell = (props) => (
  <th className="text-center border-0" style={{ width: "150px" }}>
    <span className=".k-cell-inner">
      <span className="k-link">
        <span className="k-column-title">{props.title}</span>
      </span>
    </span>
  </th>
);

export default function TimeRecorded(props) {
  const date = new Date();
  const [valueFrom, setValueFrom] = React.useState(false);
  const [valueTo, setValueTo] = React.useState(false);
  const [timeList, setTimeList] = useState({ data: [], total: 20 });
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [eventDataState, setEventDataState] = React.useState();
  const [loadedContent, setLoadedContent] = useState(true);
  const [matterId, setMatterId] = useState(null);
  const [status, setStatus] = useState({ key: "All", value: "" });
  const [allowAccessData, setAllowAccessData] = useState({});
  const { yearStartDate } = useSelector((state) => state.company.company);

  // Check if the date is valid, otherwise, return a fallback (e.g., today's date or a default)
  let yearsStartDate = new Date(yearStartDate);
  if (isNaN(yearsStartDate)) {
    yearsStartDate = new Date(); // Fallback to today's date if the yearStartDate is invalid
  }

  const [fromDate, setFromDate] = useState(
    yearsStartDate.toISOString().split("T")[0] // Extract the date part only if valid
  );
  const [toDate, setToDate] = useState(date.toISOString().split("T")[0]);
  const [updateFilter, setUpdateFilter] = useState(false);
  const [dataState, setDataState] = React.useState({
    take: 50,
    skip: 0,
    sort: [
      {
        field: "date",
        dir: "asc",
      },
    ],
  });

  const [fromDateSelector, setFromDateSelector] = useState(yearsStartDate);
  const [toDateSelector, setToDateSelector] = useState(date);

  useEffect(() => {
    if (props.refreshClicked && props.activeItem === 1) {
      ReloadData();
      props.setRefreshClicked(false);
    }
  }, [props.refreshClicked]);

  useEffect(() => {
    if (updateFilter) {
      setUpdateFilter(false);
    }
  }, [updateFilter]);

  const getAccessRights = async () => {
    try {
      const data = await getAccessRightsData();
      setAllowAccessData(data?.data?.data);
      setLoadedContent(false);
    } catch (error) {}
  };

  useEffect(() => {
    // if (!fromClient) {
    getAccessRights();
    // }
  }, []);

  const onChangeFromDateSelect = (event) => {
    if (!valueFrom) {
      setValueFrom(true);
    }
    setFromDateSelector(event.value);
    let fromDate = null;
    let date = new Date(event.value);
    if (event.value) {
      fromDate = date.toISOString().split("T")[0];
    }
    setFromDate(fromDate);
  };

  const onChangeToDateSelect = (event) => {
    if (!valueTo) {
      setValueTo(true);
    }
    setToDateSelector(event.value);
    let toDate = null;
    let date = new Date(event.value);
    if (event.value) {
      toDate = date.toISOString().split("T")[0];
    }
    setToDate(toDate);
  };

  const onChangematter = (e) => {
    let event = e.value;
    setMatterId(event);
  };

  const updateFilterValues = () => {
    const { setFilterClicked, setFilterApplied } = props;
    setUpdateFilter(true);
    setFilterClicked(false);
    if (!valueFrom && !valueTo && status.value == "") {
      setFromDateSelector(yearsStartDate);
      setToDateSelector(date);
      setFilterApplied(false);
      return;
    }
    setFilterApplied(true);
  };

  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: [10, 25, 50, 100],
    };
    return {
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  const [state, setState] = React.useState(createState(0, 50));

  const blurTimeoutRef = useRef(null);

  const onOpen = () => {
    props?.filterContentRef?.current.focus();
  };

  const onFocus = () => {
    clearTimeout(blurTimeoutRef.current);
  };

  const onBlurTimeout = () => {
    props?.setFilterClicked(false);
  };

  const onBlur = () => {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(blurTimeoutRef.current);
    };
  }, []);

  let handleChange = (e) => {
    setStatus(e.target.value);
  };

  const dataStateChange = (e) => {
    const eventObject = { event: e?.dataState };
    setEventDataState(eventObject);
    if (
      e.dataState.sort !== undefined &&
      e.dataState.skip !== 0 &&
      dataState.sort !== e.dataState.sort
    ) {
      setDataState({ ...e.dataState, skip: 0 });
    } else if (e.dataState.sort[0] === undefined) {
      setDataState({
        ...e.dataState,
        sort: [
          {
            field: dataState.sort[0].field,
            dir: "asc",
          },
        ],
      });
    } else {
      setDataState(e.dataState);
    }
    setUpdateFilter(true);
  };

  const handleReset = () => {
    setUpdateFilter(true);
    setValueFrom(false);
    setValueTo(false);
    setFromDateSelector(yearsStartDate);
    setToDateSelector(date);
    setStatus({ key: "All", value: "" });
    props.setFilterApplied(false);
    props.setFilterClicked(false);
  };

  const ReloadData = () => {
    if (eventDataState) {
      setDataState({ ...eventDataState?.event, skip: 0 });
    } else {
      setDataState({
        ...dataState,
        take: 50,
        skip: 0,
      });
    }
    setIsRefreshed(!isRefreshed);
    setUpdateFilter(true);
  };

  const dataReceived = (time) => {
    if (timeList) {
      setTimeList(time);
    }
  };

  return (
    <div className={`${props.className}`}>
      {props.fromCaseFile ? (
        <Popup
          show={props?.filterClicked}
          anchor={props?.filterAnchor?.current}
          anchorAlign={{
            horizontal: "right",
            vertical: "bottom",
          }}
          popupAlign={{
            horizontal: "right",
            vertical: "top",
          }}
          popupClass={` ${styles["popup-content"]}  `}
          onOpen={onOpen}
        >
          <div
            tabIndex={0}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={props?.filterContentRef}
            role="menu"
            className="d-flex flex-column align-items-center"
          >
            <div className="">
              <GeneraLFilters
                allowAccessData={allowAccessData}
                updateFilters={(data) => {}}
                isRefreshed={isRefreshed}
              />
            </div>
            <FilterComponent
              updateFilterValues={updateFilterValues}
              setUpdateFilter={setUpdateFilter}
              updateFilter={updateFilter}
              showPopup={true}
              valueFrom={valueFrom}
              setValueFrom={setValueFrom}
              fromDateSelector={fromDateSelector}
              onChangeFromDateSelect={onChangeFromDateSelect}
              valueTo={valueTo}
              setValueTo={setValueTo}
              toDateSelector={toDateSelector}
              onChangeToDateSelect={onChangeToDateSelect}
              statusData={statusData}
              status={status}
              handleChange={handleChange}
              handleReset={handleReset}
            />
          </div>
        </Popup>
      ) : loadedContent ? (
        <Skeleton
          shape="rectangle"
          className="mb-3"
          style={{
            width: "100%",
            height: "70px",
          }}
        />
      ) : (
        <div className="d-flex align-items-end mb-3 flex-wrap mx-auto justify-content-between">
          <div className="d-flex align-items-end gap-2">
            <GeneraLFilters
              allowAccessData={allowAccessData}
              updateFilters={(data) => {}}
              isRefreshed={isRefreshed}
              disabledFeeEarner={true}
            />
            <div className="d-flex flex-column">
              <Label className="filter-font mb-1">Matter</Label>
              <MattersCombobox
                onChangematter={onChangematter}
                value={matterId}
                width={'250px'}
              />
            </div>
          </div>
          <FilterComponent
            valueFrom={valueFrom}
            setValueFrom={setValueFrom}
            fromDateSelector={fromDateSelector}
            onChangeFromDateSelect={onChangeFromDateSelect}
            valueTo={valueTo}
            setValueTo={setValueTo}
            toDateSelector={toDateSelector}
            onChangeToDateSelect={onChangeToDateSelect}
            statusData={statusData}
            status={status}
            handleChange={handleChange}
            handleRefresh={ReloadData}
          />
        </div>
      )}

      <Grid
        {...dataState}
        data={timeList}
        onDataStateChange={dataStateChange}
        sortable={true}
        className="fs-md mt-3 casefile_time_gridheight"
        pageable={state.pageable}
        pageSize={state.pageSize}
      >
        <GridColumn
          field="date"
          title="Date"
          cell={DateFormatCell}
          width="110px"
        />
        <GridColumn field="timeTypeCode" title="Type" width="80px" />
        <GridColumn field="feeEarnerRef" title="Fee Earner" width="100px" />
        <GridColumn field="matterRef" title="Matter Ref" width="120px" />
        <GridColumn field="details" title="Details" />
        <GridColumn field="units" title="Units" width="60px" />
        <GridColumn
          field="rate"
          title="Rate"
          headerCell={CenterTextHeaderCell}
          cell={AmountFormatCell}
          width="80px"
        />
        <GridColumn
          field="value"
          title="Value"
          headerCell={CenterTextHeaderCell}
          cell={AmountFormatCell}
          width="80px"
        />
        <GridColumn
          field="billed"
          className="ms-auto"
          title="Billed"
          headerCell={CenterTextHeaderCell}
          cell={BooleanCell}
          width="80px"
        />
        <GridColumn
          field="billedNetAmount"
          title="Billed Amount"
          headerCell={CenterTextHeaderCell}
          cell={AmountFormatCell}
          width="140px"
        />
      </Grid>

      {props?.fromCaseFile && (
        <HandleGridBody
          matterId={props?.selectedMatterId}
          refresh={isRefreshed}
          updateFilter={updateFilter}
          status={
            updateFilter
              ? status
              : {
                  key: "All",
                  value: "",
                }
          }
          fD={updateFilter && valueFrom ? fromDate : ""}
          tD={updateFilter && valueTo ? toDate : ""}
          dataState={dataState}
          onDataReceived={dataReceived}
          currentComponent="timeRecorded-Matter"
        />
      )}
      {!props?.fromCaseFile && props?.parameter && (
        <HandleGridBody
          feeEarner={props?.parameter}
          refresh={isRefreshed}
          status={status}
          fD={valueFrom ? fromDate : ""}
          tD={valueTo ? toDate : ""}
          matterId={matterId?.matterId}
          dataState={dataState}
          onDataReceived={dataReceived}
          currentComponent="timeRecorded"
        />
      )}

      {props?.addTime && (
        <Dialog title={"Add Time"} onClose={props.setAddTime}>
          <div style={{ width: "1000px", overflow: "hidden" }}>
            <AddTime
              component={"modal"}
              fromCaseFile={true}
              showModal={props.setAddTime}
              matter={props?.matter}
              cancelEdit={() => props.setAddTime(false)}
              fetchTime={ReloadData}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
}
