import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { AmountFormatCell } from "components/common/formatter";
import { NumericCell } from "../DibursementPayment/DisbursementInputField";
import { DateFormatCell } from "components/common/formatter";
import { process } from "@progress/kendo-data-query";
const initialDataState = {
    sort: [{ field: "date", dir: "asc" }],
    take: 50,
    skip: 0,
};



const PaySupplierGrid = (props) => {

    const [dataState, setDataState] = useState(initialDataState);
    const [data, setData] = React.useState([]);


    const dataStateChange = (e) => {
        if (
            e.dataState.sort !== undefined &&
            e.dataState.skip !== 0 &&
            dataState.sort !== e.dataState.sort
        ) {
            setDataState({ ...e.dataState, skip: 0 });
        } else if (e.dataState.sort[0] === undefined) {
            setDataState({
                ...e.dataState,
                sort: [
                    {
                        field: dataState.sort[0].field,
                        dir: "asc",
                    },
                ],
            });
        } else {
            setDataState(e.dataState);
        }
    };

    useEffect(() => {
        if (props.data) setData(props.data);
    }, [props.data]);


    const mergeAllocation = () => {
        const result = [];
        data.map((item) => {
            if (item.add === true) {
                const data = {
                    postingId: item.postingId,
                    amount: item.amount,
                    matterId: item.matterId,
                };
                result.push(data);
            }
        });
        props.updateAllocations(result);
    };


    const calculateAmount = () => {
        let total = 0;
        data.map((item) => {
            if (
                item.add === true &&
                item.amount !== null &&
                item.amount !== undefined
            ) {
                total = total + parseInt(item.amount);
            }
        });
        props.setAmount(total);
    };


    const AmountNameHeader = (props) => {
        return (
            <div
                onClick={(e) => {
                    props.onClick(e);
                }}
                style={{ textAlign: "center", cursor: "pointer" }}
            >
                {props.title}
                {props.children}
            </div>
        );
    };


    useEffect(() => {
        mergeAllocation();
        calculateAmount();
    }, [data]);


    const itemChange = (e) => {

        let newData = data.map((item) => {
            if (item.transactionNo === e.dataItem.transactionNo) {
                if (
                    e.field === "amount" &&
                    e.value <= item.amountDue &&
                    item.amountDue >= 0
                ) {
                    item[e.field || ""] = e.value;
                } else if (
                    e.field === "amount" &&
                    e.value < 0 &&
                    e.value > item.amountDue &&
                    item.amountDue < 0
                ) {
                    item[e.field || ""] = e.value;
                } else if (e.field === "add") {
                    item[e.field || ""] = e.value;
                }
            }
            return item;
        });

        setData(newData);

        if (e.field === "amount") {
            if (
                e.dataItem.amount !== 0 &&
                e.dataItem.amount !== null &&
                e.dataItem.add === false
            ) {
                let newData = data.map((item) => {
                    if (item.transactionNo === e.dataItem.transactionNo) {
                        item.add = true;
                    }
                    return item;
                });
                setData(newData);
            } else if (
                (e.dataItem.amount === 0 || e.dataItem.amount === null) &&
                e.dataItem.add === true
            ) {
                let newData = data.map((item) => {
                    if (item.transactionNo === e.dataItem.transactionNo) {
                        item.add = false;
                    }
                    return item;
                });
                setData(newData);
            }
        }
        if (e.field === "add") {
            if (e.dataItem.add === true) {
                let newData = data.map((item) => {
                    if (item.postingId === e.dataItem.postingId) {
                        item.amount = item.amountDue;
                    }
                    return item;
                });
                setData(newData);
            } else {
                let newData = data.map((item) => {
                    if (item.postingId === e.dataItem.postingId) {
                        item.amount = 0.0;
                    }
                    return item;
                });
            }
        }
    };

    return (
        <React.Fragment>
            <Grid
                style={{ maxHeight: "calc(100vh - 220px)", overflow: "auto", width: "100%" }}
                data={process(data, dataState)}
                dataItemKey={"transactionNo"}
                onItemChange={itemChange}
                editField="inEdit"
                className="accounts-table"
                onDataStateChange={dataStateChange}
                pageable={true}
                {...dataState}
                sortable={true}
            >
                <Column
                    field="date"
                    title="Date"
                    editable={false}
                    width="100px"
                    cell={DateFormatCell}
                    headerClassName="ps-2"
                />

                {props.type !== "invoices" && (
                    <Column field="matterRef"
                        title="Matter Ref"
                        editable={false}
                        width="100px"
                        headerClassName="ps-2" />
                )}

                <Column
                    field="reference"
                    title="Ref"
                    editable={false}
                    width="110px"
                    className="text-wrap fs-sm"
                />

                <Column
                    field="details"
                    title="Details"
                    editable={false}
                    width="200px"
                    className="text-wrap"
                />


                <Column
                    field="vatAmount"
                    title="VAT"
                    editable={false}
                    cell={AmountFormatCell}
                    width="60px"
                    headerClassName="pb-3"
                    headerCell={AmountNameHeader}
                />

                <Column
                    field="grossAmount"
                    title="Gross"
                    editable={false}
                    cell={AmountFormatCell}
                    width="100px"                    
                    headerClassName="pb-3"
                    headerCell={AmountNameHeader}
                />

                <Column
                    field="amountPaid"
                    title="Paid"
                    editable={false}                    
                    width="100px"
                    cell={AmountFormatCell}
                    headerClassName="pb-3"
                    headerCell={AmountNameHeader}
                />

                <Column
                    field="amountDue"
                    title="Due"
                    editable={false}                    
                    width="100px"
                    cell={AmountFormatCell}
                    headerClassName="pb-3"
                    headerCell={AmountNameHeader}
                />

                <Column
                    field="add"
                    title="Select"
                    editor="boolean"
                    width="60px"                    
                />

                <Column
                    field="amount"
                    title="Allocate"
                    editor="numeric"
                    width={"100px"}
                    cell={NumericCell}
                    headerClassName="pb-3"
                    headerCell={AmountNameHeader}
                />
            </Grid>
        </React.Fragment>
    );
};
export default PaySupplierGrid;
