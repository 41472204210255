import React, { useEffect, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Field } from "@progress/kendo-react-form";
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import * as api from "../../api/index";
import CONSTANT from "../../CONSTANT/Matter/ComboxType";
import { Error } from "@progress/kendo-react-labels";
import { handleApiError } from "Helper";
import { preparePostingTypeData } from "./ComboRepresentationFunctions";
import { TransactionTypes } from "../../components/Enums/TransactionTypes.ts";

const requiredValidator = (value) => (value ? "" : "This field is required.");

const SearchComboBox = ({
  postingType,
  onChangeFunction,
  cbType,
  width = "255px",
  boxShadow = false,
  readOnly,
  name,
  VatCode,
  controlled = false,
  value = "",
  onChange,
  style,
  customCombo,
  visited,
  validationMessage,
  fromValue,
  toValue,
  borderRadius,
  defaultValues,
  NetAmount,
  customLabel,
  defaultValueSetter,
  required,
  customValidation,
  customStyle,
  ...others
}) => {
  const styleObject = {
    boxShadow: boxShadow ? "0px 0px 16px rgba(0, 0, 0, 0.1)" : "",
    width: width,
    borderRadius: borderRadius ? borderRadius : null,
  };
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const getInfo = (cbType) => {
    switch (cbType) {
      case CONSTANT.SR:
        return { textField: "representation", label: "Supplier Reference" };
      case CONSTANT.CR:
        return { textField: "representation", label: "Consultant Reference" };
      case CONSTANT.NR:
        return { textField: "representation", label: "Nominal Reference" };
      case CONSTANT.NB:
        return { textField: "representation", label: "Nominal Reference" };
      case CONSTANT.FBT:
        return { textField: "representation", label: "From Bank" };
      case CONSTANT.TBT:
        return { textField: "representation", label: "To Bank" };
      case CONSTANT.BRANCHES:
        return { textField: "representation", label: "Branch" };
      case CONSTANT.FE:
        return { textField: "representation", label: "Fee Earner" };
      case CONSTANT.OB:
        return { textField: "representation", label: "Office Bank" };
      case CONSTANT.CB:
        return { textField: "representation", label: "Client Bank" };
      case CONSTANT.DDAB:
        return { textField: "representation", label: "DDA Bank" };
      case CONSTANT.AVATS:
        return { textField: "representation", label: "VAT Code" };
      case CONSTANT.MATTER:
        return { textField: "matterRef", label: "Matter" };
      case CONSTANT.POSTING_TYPE:
        return { textField: "representation", label: "Posting Type" };
    }
  };

  const filterData = (filter) => {
    const data = apiData.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  const onChangeTransfer = (e) => {
    if (cbType.FBT && e.value?.nominalRef === toValue?.nominalRef) {
      return;
    } else if (cbType.TBT && e.value?.nominalRef === fromValue?.nominalRef) {
      return;
    } else {
      onChange && onChange(e);
    }
  };
  const prepareNominalData = (data, type) => {
    let array = [];
    if (type !== null) {
      data?.map((item) => {
        if (item.subType === type) {
          const templist = item;
          templist.representation =
            item.nominalRef + "  -  " + item.nominalName;
          array.push(item);
          return templist;
        }
      });
      return array;
    } else {
      data?.map((item) => {
        const templist = item;
        templist.representation = item.nominalRef + "  -  " + item.nominalName;
        array.push(item);
        return templist;
      });
      return array;
    }
  };

  const prepareSupplierRefsData = (data) => {
    let list = [];
    data.map((item) => {
      if (
        postingType === "PIN" &&
        item.transactionTypes === TransactionTypes.Purchase
      ) {
        item.representation = item.supplierRef + " - " + item.supplierName;
        list.push(item);
        return;
      } else if (
        postingType === "DIN" &&
        item.transactionTypes !== TransactionTypes.Purchase
      ) {
        item.representation = item.supplierRef + " - " + item.supplierName;
        list.push(item);
        return;
      } else {
        item.representation = item.supplierRef + " - " + item.supplierName;
        list.push(item);
        return;
      }
    });
    return list;
  };

  const prepareVatsData = (data) => {
    let list = data.map((item) => {
      const templist = item;
      templist.representation =
        item.vatCodeId + "  -  " + item.details + "  -  " + item.rate + "%";

      return templist;
    });
    return list;
  };

  const prepareFeeearnerData = (data) => {
    let list = data.map((item) => {
      const templist = item;
      templist.representation = item.userRef + "  -  " + item.displayname;

      return templist;
    });
    return list;
  };

  const getData = async (cbType) => {
    try {
      let apiRes;
      if (cbType === CONSTANT.SR) {
        const data = await api.getSupplierrefs();
        const preparedData = prepareSupplierRefsData(data?.data?.data);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.POSTING_TYPE) {
        const data = await api.getPostingtypes(postingType);
        const preparedData = preparePostingTypeData(data?.data?.data);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.BRANCHES) {
        const { data } = await api.getBranchesnames();
        const result = data.data.map((branch) => {
          branch.representation = `${branch.branchCode} - ${branch.branchName}`;
          return branch;
        });
        setApiData(result.slice());
        setData(result.slice());
        return;
      }
      if (cbType === CONSTANT.OB) {
        const { data } = await api.getOfficeBankNominals();
        const preparedData = prepareNominalData(data.data, null);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.CB) {
        const { data } = await api.getclientList();
        const preparedData = prepareNominalData(data.data, null);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.MATTER) apiRes = await api.getMatters();
      if (cbType === CONSTANT.DDAB) {
        const { data } = await api.getddaList();
        const preparedData = prepareNominalData(data.data, null);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.FE) {
        const { data } = await api.getfeeearner();
        const preparedData = prepareFeeearnerData(data.data);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        if (defaultValueSetter) {
          defaultValueSetter?.onChange(name, {
            value: preparedData.find(
              (item) => item.userRef === defaultValues?.matterFeeEarner
            ),
          });
        }
        return;
      }
      if (cbType === CONSTANT.NR) {
        const { data } = await api.getactivenominals();
        const preparedData = prepareNominalData(
          data.data,
          "ProfitAndLoss_Expense"
        );
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.NB) {
        const { data } = await api.getActiveIncomeNominals();
        const preparedData = prepareNominalData(data.data, null);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.FBT || cbType === CONSTANT.TBT) {
        const { data } = await api.getBankTransferNominals();
        const preparedData = prepareNominalData(data.data, null);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        return;
      }
      if (cbType === CONSTANT.AVATS) {
        const { data } = await api.getactiveVats();
        const preparedData = prepareVatsData(data.data);
        setApiData(preparedData.slice());
        setData(preparedData.slice());
        if (defaultValueSetter) {
          defaultValueSetter?.onChange(name, {
            value: preparedData.find(
              (item) => item.vatCodeId === defaultValues?.defaultVatCodeId
            ),
          });
        }
        return;
      }

      const { data } = apiRes;
      setApiData(data.data.slice());
      setData(data.data.slice());
    } catch (error) {
      handleApiError(error);
    }
  };
  useEffect(() => {
    getData(cbType);
  }, [postingType]);

  return (
    <div className="flex flex-column" style={style}>
      <Label className="label">{customLabel || getInfo(cbType)?.label}</Label>
      {controlled ? (
        <Field
          {...others}
          textField={getInfo(cbType)?.textField}
          disabled={readOnly}
          data={data}
          iconClassName="wa"
          value={value}
          name={name}
          onChange={(e) => {
            onChangeFunction(e);
          }}
          style={styleObject}
          filterable={true}
          onFilterChange={filterChange}
          component={ComboBox}
        />
      ) : customCombo ? (
        <ComboBox
          {...others}
          textField={getInfo(cbType)?.textField}
          disabled={readOnly}
          data={data}
          iconClassName="wa"
          value={value}
          required={required}
          name={name}
          onChange={(e) => {
            onChangeFunction(e);
          }}
          style={styleObject}
          filterable={true}
          onFilterChange={filterChange}
        />
      ) : (
        <>
          <Field
            {...others}
            validator={!customValidation && requiredValidator}
            textField={getInfo(cbType)?.textField}
            disabled={readOnly}
            name={name} // issue is onChange can only be used on
            data={data}
            value={value}
            iconClassName="wa"
            style={
              customStyle ? { ...styleObject, ...customStyle } : styleObject
            }
            filterable={true}
            onFilterChange={filterChange}
            component={ComboBox}
          />
          {visited && validationMessage && <Error>{validationMessage}</Error>}
        </>
      )}
    </div>
  );
};

export default SearchComboBox;
