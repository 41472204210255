import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Avatar, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import MattersDetailsPage from "./MattersDetailsPage";
import CaseActivitiesPage from "../CaseFile/CaseActivities/CaseActivitiesPage";
import MatterLedgerCardPage from "./MattersLedgerCardPage";
import MatterForm from "./MatterForm";
import { getMattersAllInfo } from "actions/matters.action";
import { PageTabItemTitle } from "components/tabs/TabItem/PageTabItemTitle";
import CaseWorkFlows from "../CaseFile/CaseWorkFlow/CaseWorkFlow";
import { handleCaseFilesTabs } from "actions/casefiles.action";
import { useNavigate } from "react-router";
import MattersCreditsandDefaults from "./MattersCreditsDefaults";
import { DocRegisterListTile } from "components/DocumentRegister/DocRegisterListTile";
import { NotesListTile } from "components/Notes/NotesListTile";
import MattersCompletionPage from "./MatterCompletionPage";
import { RiskAssessment } from "components/RiskAssessments";
import { useAllowedLiscence } from "hooks/useAllowedLiscence";
import { License } from "components/Enums/TabEnums.ts";

export const NameAlias = (props) => {
  return (
    <Avatar
      type={"image"}
      shape={"circle"}
      style={{ cursor: "pointer", backgroundColor: "#FFB800", color: "black" }}
    >
      {props.data}
    </Avatar>
  );
};

/*const options = ["Details", "Activities", "Tasks", "Ledger Card", "Work Flows"];*/
const options = [
  "Details",
  "Credits & Defaults",
  "Completion",
  "Wills & Deeds",
  "Notes",
  "Risk Assessment",
];

const MatterView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const { subDataCheck } = useAllowedLiscence();
  const matterDetails = useSelector((state) => state.mattersList);
  const [currentMatterDetails, setcurrentMatterDetails] = useState();
  const [actiVeItem, setActiveItem] = useState(0);
  const openCaseFile = () => {
    navigate(`/casefiles/${currentMatterDetails?.matterId}`);
  };

  useEffect(() => {
    if (matterDetails !== null) {
      setcurrentMatterDetails(
        matterDetails?.matterViewDetails[props.reference]?.data
      );
    }
  }, [matterDetails]);

  useEffect(() => {
    if (!showForm && currentMatterDetails?.matterId) {
      dispatch(getMattersAllInfo(currentMatterDetails?.matterId));
    }
  }, [showForm]);

  return (
    <div className="pt-3 ps-2 pe-2">
      <div className="row">
        <div className={`styles["view-tittle"] col`}>
          {/* <div className="col d-flex justify-content-end">
            {actiVeItem !== 6 && (
              <div className="">
                {subDataCheck().includes(License.CASE_MANAGEMENT) && (
                  <Button
                    className="common-btn mattersBtn me-2"
                    style={{}}
                    onClick={openCaseFile}
                  >
                    Case File
                  </Button>
                )}
                <Button
                  className="common-btn"
                  style={{}}
                  onClick={() => setShowForm(true)}
                >
                  Edit
                </Button>
              </div>
            )}
          </div> */}

          <TabStrip
            onSelect={(e) => setActiveItem(e.selected)}
            keepTabsMounted={true}
            selected={actiVeItem}
            style={{ marginTop: "10px" }}
            className="pagetab px-4 h-100"
            animation="fade"
          >
            {options.map((item) => {
              return (
                <TabStripTab
                  title={
                    <PageTabItemTitle
                      index={options.indexOf(item)}
                      title={item}
                      selected={actiVeItem}
                    />
                  }
                  key={item}
                >
                  <div style={{ width: "100%" }}>
                    {item === "Details" && (
                      <MattersDetailsPage reference={props.reference} />
                    )}
                    {item === "Credits & Defaults" && (
                      <MattersCreditsandDefaults reference={props.reference} />
                    )}
                    {item === "Completion" && (
                      <MattersCompletionPage reference={props.reference} />
                    )}
                    {item === "Wills & Deeds" && (
                      <DocRegisterListTile matterId={currentMatterDetails} />
                    )}

                    {item === "Notes" && (
                      <NotesListTile
                        matterId={currentMatterDetails?.matterId}
                      />
                    )}

                    {item === "Risk Assessment" && (
                      <RiskAssessment
                        matterId={currentMatterDetails?.matterId}
                      />
                    )}

                    {item === "Activities" && (
                      <CaseActivitiesPage
                        addFormText={
                          currentMatterDetails?.matterRef +
                          "-" +
                          currentMatterDetails?.details
                        }
                        reference={currentMatterDetails?.matterId}
                        matterDetails={currentMatterDetails}
                      />
                    )}
                    {item === "Ledger Card" && (
                      <MatterLedgerCardPage
                        selTab={props.selTab}
                        reference={currentMatterDetails?.matterId}
                      />
                    )}
                    {item === "Work Flows" && (
                      <CaseWorkFlows
                        selTab={props.selTab}
                        reference={currentMatterDetails}
                      />
                    )}
                  </div>
                </TabStripTab>
              );
            })}
          </TabStrip>
        </div>
      </div>

      {showForm && (
        <MatterForm
          reference={props.reference}
          isView={true}
          setshowMatterForm={() => setShowForm(false)}
        />
      )}
    </div>
  );
};

export default MatterView;
