import React, { useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { DocumentsUpload } from "../../pages/Postings/DcoumentsUpload";
import { onFileUpload } from "Services/fileDataService";
import SearchComboBox from "components/common/SearchComboBox";
import { Error } from "@progress/kendo-react-labels";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import MattersCombobox from "pages/Postings/MattersComboBox";
import { useUploadForm } from "components/fileUploadHook";
import {
  validateCounselFee,
  validateAddReference,
  validateDate,
  validateDetails,
  validateNetAmount,
  validatePayee,
  validatePayer,
  ValidateVATAmount,
  ValidateNetAmount,
} from "../BatchPosting/InputComponents";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import Modal from "pages/Settings/Modal";
import { useEffect } from "react";
export const MatterDocPostingTypes = [
  "DUU",
  "DPU",
  "DIN",
  "OCR",
  "CTO",
  "ODR",
  "CCR",
  "CDR",
  "DCR",
  "DDR",
  "OTC",
];
export const SupplierDocPostingTypes = ["PIN"];
export const NominalDocPostingTypes = ["BP", "BR", "BT"];

export default function BacthPostinForm({
  postingType,
  type,
  onChagngePosting,
  openChits,
  setInitialData,
  matterId,
  matterIdTo,
  setMatterIdTo,
  isUpdate,
  list,
  updateDataIndex,
  setList,
  setIsUpdate,
  // setErrorModal,
  setRenderList,
  postingCodeEdit,
  renderList,
  onChangematter,
  initialData,
  vatCode,
  setVatCode,
  setUpdateDataIndex,
  item,
}) {
  const [visible, setVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [resetDocs, setresetDocs] = useState(false);
  const [supplierRef, setSupplierRef] = useState(null);
  const [nominalRef, setNominalRef] = useState(null);
  const [documentUpload, setDocumentsUpload] = useState(false);
  const [postingValueForDoc, setPostValueForDoc] = useState(null);
  const { isSuccess, uploadForm, progress, reset } = useUploadForm();
  const [errorModal, setErrorModal] = useState(false);
  const [prevPostingCode, setPrevPostingCode] = useState({});

  const handleChange = (event) => {
    if (!event) return;
    if (filesData[0]) {
      setErrorModal(true);
      return;
    }
    setPostValueForDoc(event.value);
    onChagngePosting(event);
  };

  const addNew = () => {
    setInitialData({ date: new Date(Date.now()) });
    setFilesData([]);
  };

  const handleMatterChange = (e) => {
    if (
      MatterDocPostingTypes.includes(postingValueForDoc?.code) &&
      filesData.length
    ) {
      setErrorModal(true);
      return;
    }
    onChangematter(e);
  };

  const handleClearFile = (event) => {
    setFilesData([]);
  };

  const handleChangeSupplier = (e) => {
    if (
      SupplierDocPostingTypes.includes(postingValueForDoc?.code) &&
      filesData.length
    ) {
      setErrorModal(true);
      return;
    }
    setSupplierRef(e.value);
  };

  const handleChangeNominal = (e) => {
    if (
      NominalDocPostingTypes.includes(postingValueForDoc?.code) &&
      filesData.length
    ) {
      setErrorModal(true);
      return;
    }
    setNominalRef(e.value);
  };

  useEffect(() => {
    if (postingCodeEdit) {
      setPostValueForDoc({
        representation: `${postingCodeEdit?.code} - ${postingCodeEdit?.name}`,
        ...postingCodeEdit,
      });
      setVatCode(initialData?.vatCode);
    }
  }, [postingCodeEdit]);

  console.log("initialData", initialData);

  const handleFormSubmit = (values, e) => {
    console.log("values+++", values);
    let code = postingValueForDoc.postingType;
    values.postingType = postingValueForDoc;
    values.documentFiles = filesData;
    values.matterID = matterId;
    values.supplierRef = supplierRef;
    values.nominalRef = nominalRef;
    values.matterIDTo = matterIdTo;
    values.vatCode = vatCode;
    if (
      code?.code === "BT" &&
      values?.officeBank?.nominalId === values?.officeBankTo?.nominalId
    ) {
      setIsError(true);
      return;
    }
    if (values.vatAmount === undefined) {
      values.vatAmount = (values?.vatCode?.rate / 100) * values?.netAmount;
      if (values.vatAmount === null || isNaN(values.vatAmount)) {
        values.vatAmount = 0.0;
      }
    }
    if (isUpdate && list) {
      const listClone = [...list];
      listClone[updateDataIndex] = { ...values };
      setList([...listClone]);
      setIsUpdate(false);
    } else {
      if (!list) return;
      setList([...list, values]);
    }

    setIsError(false);
    addNew();
    document.getElementById("reset-button").click();
    onChangematter({});
    setMatterIdTo();
    setVatCode(null);
    // setRenderList([code?.code]);
    // setPrevPostingCode(code);
    // setTimeout(() => {
    //   document.getElementById("posting-btn").click();
    // }, 500);
  };

  const handleUpload = async (e) => {
    let data = onFileUpload(e);
    setFilesData([...filesData, data]);

    const parameters = {
      documentType: FileDocumentTypes.Posting,
      matterId: MatterDocPostingTypes.includes(postingValueForDoc?.code)
        ? matterId?.matterId
        : undefined,
      supplierRef: SupplierDocPostingTypes.includes(postingValueForDoc?.code)
        ? supplierRef?.supplierRef
        : undefined,
      nominalRef: NominalDocPostingTypes.includes(postingValueForDoc?.code)
        ? nominalRef?.nominalRef
        : undefined,
    };
    const dataChoosen = await uploadForm(data, e.target.files[0], parameters);
    data.fileID = dataChoosen.data.data.fileID;
  };
  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  const requiredValidator = (value) => (value ? "" : "This field is required.");

  const getNominalType = (type) => {
    if (type === "BR" || type === "BP") {
      return CONSTANT.NB;
    } else if (type === "PIN") {
      return CONSTANT.NR;
    } else if (type === "BT") {
      return CONSTANT.BT;
    }
  };
  const getPostingCompatibility = () => {
    if (postingValueForDoc === null) return false;
    if (
      MatterDocPostingTypes.includes(postingValueForDoc?.code) &&
      matterId !== null
    )
      return true;
    if (
      SupplierDocPostingTypes.includes(postingValueForDoc?.code) &&
      supplierRef !== null
    )
      return true;
    if (
      NominalDocPostingTypes.includes(postingValueForDoc?.code) &&
      nominalRef !== null
    )
      return true;
    return false;
  };

  const notAllowedChange = () =>
    MatterDocPostingTypes.includes(postingValueForDoc?.code) &&
    filesData.length;

  return (
    <>
      <div
        className="bgForm ms-2 pt-4 d-flex justify-content-center align-item-center"
        style={{
          maxWidth: "380px",
          overflow: "auto",
          maxHeight: type === "chitPosting" ? "750px" : "calc(100vh - 130px)",
          height: type === "chitPosting" ? "99%" : "calc(100vh - 130px)",
        }}
      >
        <Form
          ignoreModified={true}
          onSubmit={handleFormSubmit}
          initialValues={initialData}
          key={JSON.stringify(initialData)}
          render={(formRenderProps) => {
            return (
              <div>
                <FormElement>
                  <fieldset>
                    {formRenderProps.visited &&
                      formRenderProps.errors &&
                      formRenderProps.errors.VALIDATION_SUMMARY && (
                        <div className={"k-messagebox k-messagebox-error"}>
                          {formRenderProps.errors.VALIDATION_SUMMARY}
                        </div>
                      )}

                    <div className="mb-2">
                      <div className="mb-2">
                        <SearchComboBox
                          onChangeFunction={(e) => handleChange(e)}
                          // controlled={true}
                          customCombo
                          value={postingValueForDoc}
                          name="postingType"
                          cbType={CONSTANT.POSTING_TYPE}
                          width="auto"
                          boxShadow={true}
                          postingType={postingType}
                        />
                      </div>
                    </div>

                    {!renderList?.some((el) =>
                      ["PIN", "BP", "BR", "BT", "CTC"].includes(el)
                    ) && (
                      <div className="mb-2">
                        <Label className="label">Matter</Label>
                        <div>
                          <MattersCombobox
                            style={{
                              boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            }}
                            filesData={filesData}
                            onChangematter={(e) => {
                              if (e.value === null || !e?.value?.feeEarnerRef) {
                                formRenderProps.onChange("feeEarnerRef", {
                                  value: null,
                                });
                              } else {
                                formRenderProps.onChange("feeEarnerRef", {
                                  value: {
                                    userRef: e?.value?.feeEarnerRef,
                                    representation: `${e?.value?.feeEarnerRef} - ${e.value?.feeEarnerName}`,
                                  },
                                });
                              }
                              handleMatterChange(e);
                            }}
                            name="matterID"
                            value={matterId}
                            setErrorModal={setErrorModal}
                            notAllowedChange={notAllowedChange}
                            width="100%"
                          />
                        </div>
                      </div>
                    )}

                    {renderList?.some((el) => ["CTC"].includes(el)) && (
                      <div className="mb-2">
                        <Label className="label">From Matter</Label>
                        <div>
                          <MattersCombobox
                            style={{
                              boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            }}
                            filesData={filesData}
                            onChangematter={(e) => {
                              if (e.value === null || !e?.value?.feeEarnerRef) {
                                formRenderProps.onChange("feeEarnerRef", {
                                  value: null,
                                });
                              } else {
                                formRenderProps.onChange("feeEarnerRef", {
                                  value: {
                                    userRef: e?.value?.feeEarnerRef,
                                    representation: `${e?.value?.feeEarnerRef} - ${e.value?.feeEarnerName}`,
                                  },
                                });
                              }
                              handleMatterChange(e);
                            }}
                            name="matterIDFrom"
                            value={matterId}
                            setErrorModal={setErrorModal}
                            notAllowedChange={notAllowedChange}
                            width="100%"
                          />
                        </div>
                      </div>
                    )}

                    {renderList?.some((el) => ["CTC"].includes(el)) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="clientBankFrom"
                          cbType={CONSTANT.CB}
                          customLabel={"From Client Bank"}
                          width="auto"
                          boxShadow={true}
                        />
                      </div>
                    )}

                    {renderList?.some((el) => ["CTC"].includes(el)) && (
                      <div className="mb-2">
                        <Label className="label">To Matter</Label>
                        <div>
                          <MattersCombobox
                            style={{
                              boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            }}
                            filesData={filesData}
                            onChangematter={(e) => {
                              // if (e.value === null) {
                              //   formRenderProps.onChange("feeEarnerRef", {
                              //     value: null,
                              //   });
                              // } else {
                              //   formRenderProps.onChange("feeEarnerRef", {
                              //     value: {
                              //       userRef: e?.value?.feeEarnerRef,
                              //       representation: `${e?.value?.feeEarnerRef} - ${e.value?.feeEarnerName}`,
                              //     },
                              //   });
                              // }
                              // handleMatterChange(e);
                              setMatterIdTo(e.value);
                            }}
                            name="matterIDTo"
                            value={matterIdTo}
                            setErrorModal={setErrorModal}
                            notAllowedChange={notAllowedChange}
                            width="100%"
                          />
                        </div>
                      </div>
                    )}

                    {renderList?.some((el) => ["CTC"].includes(el)) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="clientBankTo"
                          cbType={CONSTANT.CB}
                          customLabel={"To Client Bank"}
                          width="auto"
                          boxShadow={true}
                        />
                      </div>
                    )}

                    {renderList?.some((el) => ["DIN", "PIN"].includes(el)) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="supplierRef"
                          customCombo
                          value={supplierRef}
                          onChangeFunction={handleChangeSupplier}
                          cbType={CONSTANT.SR}
                          postingType={postingValueForDoc?.code}
                          width="auto"
                          boxShadow={true}
                        />
                      </div>
                    )}

                    <div className="d-flex mb-2">
                      <div style={{ width: "150px", marginRight: "15px" }}>
                        <Field name="date" component={validateDate} />
                      </div>
                      <div style={{ width: "156px" }}>
                        <Field
                          validator={requiredValidator}
                          name="reference"
                          component={validateAddReference}
                        />
                      </div>
                    </div>

                    <div
                      className={`d-flex  ${
                        renderList[0] === "CTO"
                          ? " flex-column"
                          : " flex-column-reverse"
                      }`}
                    >
                      {renderList?.some((el) =>
                        ["CCR", "CDR", "CTO", "OTC"].includes(el)
                      ) && (
                        <div className="mb-2">
                          <SearchComboBox
                            name="clientBank"
                            cbType={CONSTANT.CB}
                            width="auto"
                            boxShadow={true}
                          />
                        </div>
                      )}
                      {renderList?.some((el) =>
                        [
                          "OCR",
                          "ODR",
                          "CTO",
                          "OTC",
                          "BP",
                          "BR",
                          "DPU",
                        ].includes(el)
                      ) && (
                        <div className="mb-2">
                          <SearchComboBox
                            name="officeBank"
                            cbType={CONSTANT.OB}
                            width="auto"
                            boxShadow={true}
                          />
                        </div>
                      )}
                    </div>
                    {renderList?.some((el) =>
                      ["PIN", "BP", "BR"].includes(el)
                    ) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="nominalRef"
                          cbType={getNominalType(postingValueForDoc?.code)}
                          width="auto"
                          onChangeFunction={handleChangeNominal}
                          customCombo
                          value={nominalRef}
                          boxShadow={true}
                        />
                      </div>
                    )}

                    {renderList?.some((el) => ["BT"].includes(el)) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="officeBank"
                          cbType={CONSTANT.FBT}
                          width="auto"
                          boxShadow={true}
                          toValue={formRenderProps?.valueGetter("officeBankTo")}
                        />
                      </div>
                    )}

                    {renderList?.some((el) => ["BT"].includes(el)) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="officeBankTo"
                          cbType={CONSTANT.TBT}
                          width="auto"
                          boxShadow={true}
                          fromValue={formRenderProps?.valueGetter(
                            "officeBankFrom"
                          )}
                        />
                      </div>
                    )}
                    {isError && (
                      <Error className="mt-1 mb-2">
                        From and To office banks should be different
                      </Error>
                    )}

                    {renderList?.some((el) => ["DCR", "DDR"].includes(el)) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="ddaBank"
                          cbType={CONSTANT.DDAB}
                          width="auto"
                          boxShadow={true}
                        />
                      </div>
                    )}

                    <div className="mb-2">
                      <Field
                        validator={requiredValidator}
                        name="netAmount"
                        component={ValidateNetAmount}
                        formRenderProps={formRenderProps}
                        NetAmount={formRenderProps.valueGetter("netAmount")}
                        VatCode={vatCode}
                      />
                    </div>

                    {!renderList?.some((el) =>
                      [
                        "CCR",
                        "CDR",
                        "OCR",
                        "DCR",
                        "DDABank",
                        "ODR",
                        "DDR",
                        "CTO",
                        "OTC",
                        "CTC",
                        "BT",
                      ].includes(el)
                    ) && (
                      <div className="d-flex mb-2 ">
                        <div style={{ width: "160px", marginRight: "10px" }}>
                          <SearchComboBox
                            name="vatCode"
                            cbType={CONSTANT.AVATS}
                            width="auto"
                            customCombo={true}
                            required={true}
                            onChangeFunction={(e) => {
                              const vatRate = e?.value?.rate || 0; // Use 0 if VatCode is not provided or invalid
                              const calculatedVat =
                                formRenderProps.valueGetter("netAmount") *
                                (vatRate / 100);
                              formRenderProps.onChange("vatAmount", {
                                value: !isNaN(calculatedVat)
                                  ? calculatedVat
                                  : 0.0,
                              });
                              setVatCode(e.value);
                            }}
                            value={vatCode}
                            boxShadow={true}
                            defaultValueSetter={formRenderProps}
                          />
                        </div>
                        <div style={{ width: "156px" }}>
                          <Field
                            name="vatAmount"
                            component={ValidateVATAmount}
                            NetAmount={formRenderProps.valueGetter("netAmount")}
                            VatCode={formRenderProps.valueGetter("vatCode")}
                          />
                        </div>
                      </div>
                    )}
                    {!renderList?.some((el) =>
                      ["BP", "BR", "BT"].includes(el)
                    ) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="feeEarnerRef"
                          cbType={CONSTANT.FE}
                          width="auto"
                          boxShadow={true}
                        />
                      </div>
                    )}

                    {renderList?.some((el) =>
                      ["OCR", "CCR", "DCR", "BR", "BT"].includes(el)
                    ) && (
                      <div className="mb-2">
                        <Field
                          validator={requiredValidator}
                          name="payer"
                          component={validatePayer}
                        />
                      </div>
                    )}

                    {renderList?.some((el) =>
                      ["CDR", "ODR", "DDR", "BP", "DPU"].includes(el)
                    ) && (
                      <div className="mb-2">
                        <Field
                          validator={requiredValidator}
                          name="payee"
                          component={validatePayee}
                        />
                      </div>
                    )}

                    {renderList?.some((el) =>
                      ["PIN", "BP", "BR"].includes(el)
                    ) && (
                      <div className="mb-2">
                        <SearchComboBox
                          name="branchId"
                          cbType={CONSTANT.BRANCHES}
                          width="auto"
                          boxShadow={true}
                        />
                      </div>
                    )}

                    <div className="mb-2">
                      <Field
                        validator={requiredValidator}
                        name="details"
                        component={validateDetails}
                      />
                    </div>

                    {renderList?.some((el) => ["DUU", "DIN"].includes(el)) && (
                      <Field
                        name="isCounselFee"
                        component={validateCounselFee}
                      />
                    )}

                    <div
                      className={`d-flex mt-2 justify-content-between align-items-center ${
                        type === "chitPosting" && "justify-content-end"
                      }`}
                    >
                      {type !== "chitPosting" && (
                        <p
                          className="me-3 mt-2"
                          onClick={openChits}
                          type="button"
                          style={{ color: "#6171FF", fontSize: "15px" }}
                          disabled={
                            postingValueForDoc === "" ||
                            postingValueForDoc === null
                          }
                        >
                          <u>Select Chit</u>
                        </p>
                      )}
                      <div className="ms-3">
                        <DocumentsUpload
                          position="static"
                          open={documentUpload}
                          value={postingValueForDoc}
                          type={"All Postings"}
                          disabled={!getPostingCompatibility()}
                          reset={resetDocs}
                          progress={progress}
                          filesData={filesData}
                          onFileRemove={onFileRemove}
                          onError={() => {
                            const newFiles = filesData.splice(
                              filesData.length,
                              1
                            );
                            setFilesData([...newFiles]);
                          }}
                          onUpload={handleUpload}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="d-none"
                      id="reset-button"
                      onClick={() => {
                        formRenderProps.onFormReset();
                      }}
                    >
                      clc
                    </button>
                    {/* <button
                      type="button"
                      id="posting-btn"
                      className="d-none"
                      onClick={(e) => {
                        e.preventDefault();
                        formRenderProps.onChange("postingType", {
                          value: prevPostingCode,
                        });
                      }}
                    >
                      Clear price
                    </button> */}
                    <div className="d-flex mb-2 justify-content-end">
                      {isUpdate && (
                        <div className="k-form-buttons me-5">
                          <button
                            type="button"
                            className="common-btn border-0"
                            onClick={() => {
                              setIsUpdate(false);
                              setRenderList([]);
                              setVisible(false);
                              setUpdateDataIndex(0);
                              onChangematter({});

                              setInitialData({ date: new Date(Date.now()) });
                            }}
                          >
                            Cancel Update
                          </button>
                        </div>
                      )}
                      <div className="k-form-buttons">
                        <button type="submit" className="common-btn border-0">
                          {isUpdate ? "Update" : "Add to batch"}
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              </div>
            );
          }}
        />
        <Modal
          value={errorModal}
          error={true}
          reset={() => {
            setErrorModal(false);
          }}
          onCancel={() => {
            setErrorModal(false);
          }}
          component={"Posting"}
          display={
            "You have unposted files! By changing posting type you will lose files!"
          }
          clearFile={handleClearFile}
        ></Modal>
      </div>
    </>
  );
}
