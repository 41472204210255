import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./DocumentList.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import {
  addClientDocumentItem,
  getDocument,
  updateClientDocumentItem,
} from "api/index";
import {
  validateDate,
  validateDetails,
  validateNotes,
} from "../BatchPosting/InputComponents";
import { CONSTANT } from "CONSTANT/Matter/MatterForm";
import MatterModal from "components/Matters/MatterModal";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { useUploadForm } from "components/fileUploadHook";
import { FileDocumentTypes } from "components/Enums/FileDocumentTypes.ts";
import { onFileUpload } from "Services/fileDataService";
import SearchComboBox from "components/common/SearchComboBox";
import { RiUpload2Fill } from "react-icons/ri";
import DocumentViewer from "components/common/DocumentViewer";
import { Tile } from "components/UIComponents/Tile";
import { FileDragDrop } from "pages/CaseFile/CaseActivities/CaseActivitiesPage";

const ClientDocumentModal = (props) => {
  const [isView] = useState(props.docId ? true : false);
  const [filesData, setFilesData] = useState([]);
  const { uploadForm } = useUploadForm();
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  const feeEarnerClient = feeearnerdata?.filter(
    (item) => item.userRef === props.clientId?.feeEarnerRef
  )[0];
  const [initialData, setInitialData] = useState({
    date: new Date(),
    feeEarnerRef: feeEarnerClient
      ? {
          ...feeEarnerClient,
          representation: `${feeEarnerClient?.userRef} - ${feeEarnerClient?.displayname}`,
        }
      : null,
  });
  const filePickerRef = React.useRef();
  const uploadRef = React.createRef();
  let locale = useSelector((state) => state.company.company.locale);
  const [isLoading, setIsLoading] = useState(props.docId ? true : false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [details, setDetails] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(undefined);
  const [newMaterId, setNewMatterId] = useState({
    matterId: props?.matterId?.matterId,
    matterRef: props?.matterId?.matterRef,
    matterDetails: props?.matterId?.matterDetails,
  });
  const [inputState, setInputState] = useState({
    annualFee: null,
  });

  const handleUpload = async (e) => {
    try {
      setIsFileLoading(true);
      let data = onFileUpload(e);
      const parameters = {
        documentType: FileDocumentTypes.ClientDocs,
        clientId: props?.clientId?.clientId,
      };
      const dataChoosen = await uploadForm(data, e.target.files[0], parameters);
      data.fileID = dataChoosen.data.data.fileID;
      setFilesData([
        {
          ...dataChoosen?.data?.data,
        },
      ]);
    } catch (error) {
    } finally {
      setIsFileLoading(false);
    }
  };

  const handleDrop = (event) => {
    if (!props.docId?.documentId) {
      handleUpload({
        target: {
          files: [...event],
        },
      });
    }
  };

  React.useEffect(() => {
    if (props.droppedFiles?.length) {
      handleUpload({
        target: {
          files: props.droppedFiles,
        },
      });
    }
  }, [props.droppedFiles]);

  const getDocuemntData = async (id) => {
    try {
      if (id) {
        const { data } = await getDocument(id);
        setFilesData([data?.data?.documentFile]);
      }
    } catch (error) {}
  };

  const handleFormSubmit = async (values, e) => {
    e.preventDefault();
    if (props.docId) {
      const dataObject = {
        ...values,
        feeEarnerRef: values?.feeEarnerRef?.userRef || values?.feeEarnerRef,
      };
      try {
        const res = await updateClientDocumentItem(
          {
            data: dataObject,
          },
          props.clientId?.clientId,
          props.docId?.clientDocumentID
        );
        if (res?.status === 200) {
          props.updateData();
          toast.success("Doocument Register Updated");
        }
        return;
      } catch (err) {
        toast.error("Error Occurred while updating");
      }
    } else {
      const dataObject = {
        ...values,
        fileId: filesData[0]?.fileID,
        feeEarnerRef: values?.feeEarnerRef?.userRef,
      };
      try {
        const res = await addClientDocumentItem(
          { data: dataObject },
          props.clientId.clientId
        );
        if (res?.status === 200) {
          props.updateData();
          toast.success("Document Regsiter added");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const requiredValidator = (value) => (value ? "" : "This field is required.");

  const handleModalClose = () => {
    setShowModal(false);
    if (modalSuccess) {
      const myObj = {
        dataItem: {
          matterId: newMaterId,
          matterRef: inputState[CONSTANT.INPUT.MATTER_REF],
          matterDetails: details,
        },
      };
      props.setshowMatterForm();
      if (!props.isView) {
        props.onMatterRefClickActions(myObj);
      }
    }
  };

  const initialLoad = async () => {
    try {
      const feeEarner = feeearnerdata?.filter(
        (item) => item.userRef === props.docId?.feeEarnerRef
      )[0];
      setInitialData({
        ...props.docId,
        date: new Date(props.docId?.date),
        feeEarnerRef: feeEarner
          ? {
              ...feeEarner,
              representation: `${feeEarner?.userRef} - ${feeEarner?.displayname}`,
            }
          : null,
      });
      await getDocuemntData(props.docId?.documentId);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.docId) {
      initialLoad();
    }
  }, []);

  return (
    <div>
      <Dialog
        className={styles["matter-form-dialog"]}
        title={
          isView
            ? "View Document"
            : props.docId
            ? "Edit Document"
            : "Add Document"
        }
        onClose={props.setshowMatterForm}
      >
        <div
          className="pb-5 overflow-hidden"
          style={{ width: "970px", height: "70vh" }}
        >
          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center h-100"
              style={{ minHeight: "580px" }}
            >
              <Loader themeColor={"warning"} size={"large"} type={"pulsing"} />
            </div>
          ) : (
            <>
              <div className="">
                <div className="row">
                  <div
                    className="col-4"
                    style={{
                      width: "380px",
                    }}
                  >
                    <div
                      className="p-3 pt-4 ps-5 pe-4 overflow-auto d-flex justify-content-center bgForm"
                      style={{
                        height: "80vh",
                      }}
                    >
                      <Form
                        ignoreModified={true}
                        onSubmit={handleFormSubmit}
                        initialValues={initialData}
                        key={JSON.stringify(initialData)}
                        render={(formRenderProps) => {
                          return (
                            <div>
                              <FormElement>
                                <fieldset>
                                  {formRenderProps.visited &&
                                    formRenderProps.errors &&
                                    formRenderProps.errors
                                      .VALIDATION_SUMMARY && (
                                      <div
                                        className={
                                          "k-messagebox k-messagebox-error"
                                        }
                                      >
                                        {
                                          formRenderProps.errors
                                            .VALIDATION_SUMMARY
                                        }
                                      </div>
                                    )}

                                  <div className="d-flex mb-3  note-date-picker">
                                    <div
                                      style={{
                                        // width: "150px",
                                        marginRight: "15px",
                                      }}
                                    >
                                      <Field
                                        name="date"
                                        component={validateDate}
                                        validator={requiredValidator}
                                      />
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <SearchComboBox
                                      name="feeEarnerRef"
                                      cbType={"Fee Earner"}
                                      width="260px"
                                      boxShadow={true}
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Field
                                      validator={requiredValidator}
                                      name="details"
                                      component={validateDetails}
                                      width={"260px"}
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Field
                                      validator={requiredValidator}
                                      name="notes"
                                      component={validateNotes}
                                      style={{
                                        width: "260px",
                                      }}
                                    />
                                  </div>

                                  <button
                                    type="submit"
                                    className="d-none"
                                    id="submit"
                                    // onClick={() => {
                                    //   formRenderProps.onFormReset();
                                    // }}
                                  >
                                    clc
                                  </button>
                                </fieldset>
                              </FormElement>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-7 mt-3 ms-1">
                    <FileDragDrop
                      onFilesDrop={handleDrop}
                      uploadRef={uploadRef}
                    >
                      <Tile
                        heading="Document"
                        height={"600px"}
                        className="mb-1 ps-4 pe-4 overflow-hidden"
                      >
                        {filesData[0] ? (
                          <div
                            className="mt-2"
                            style={{
                              width: "90%",
                              margin: "auto",
                            }}
                          >
                            <DocumentViewer
                              data={filesData}
                              onScreenComponent={true}
                              iconLess={true}
                            />
                          </div>
                        ) : (
                          <div
                            onClick={(e) => filePickerRef?.current?.click()}
                            className={`d-flex align-items-center cursor-pointer justify-content-center`}
                            style={{
                              height: "80%",
                            }}
                          >
                            {isFileLoading ? (
                              <div>
                                <div
                                  className="spinner-border spinner"
                                  role="status"
                                  // style={{ marginRight: "60px" }}
                                ></div>
                              </div>
                            ) : (
                              <>
                                <div className="mt-3">
                                  <p className={styles["doc-upload-text"]}>
                                    {filesData[0]?.downloadURL
                                      ? "Update"
                                      : "Attach"}
                                    <br /> Documents
                                  </p>
                                </div>
                                <div className={styles["vertical-rule"]}></div>
                                <div
                                  className={`${styles["file-upload-icon"]} d-flex align-items-center justify-content-center position-relative`}
                                >
                                  <RiUpload2Fill color="#DCDAFF" size={20} />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Tile>
                    </FileDragDrop>
                  </div>
                </div>
              </div>
              <button
                id="doc-reg"
                style={{
                  visibility: "hidden",
                }}
                type="submit"
              ></button>
            </>
          )}
        </div>

        <input
          name="file"
          type="file"
          onChange={handleUpload}
          ref={filePickerRef}
          style={{
            visibility: "hidden",
          }}
        />

        <div className="dialog-footer">
          <Button
            className="common-btn bgBtnSecondary border-0 me-3"
            onClick={props.setshowMatterForm}
          >
            Cancel
          </Button>
          <Button
            className="common-btn me-4"
            type="button"
            form="matterForm"
            onClick={(e) => {
              document.getElementById("submit").click();
            }}
          >
            {"Save"}
          </Button>
        </div>
      </Dialog>
      <MatterModal
        showModal={showModal}
        message={modalMessage}
        success={modalSuccess}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default ClientDocumentModal;
