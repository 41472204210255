import * as React from "react";
// import Modal from "../Modal";
import { Button } from "@progress/kendo-react-buttons";
import * as api from "../../api/index";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Form, FormElement } from "@progress/kendo-react-form";
import { useEffect } from "react";
import { DocumentsUpload } from "../Postings/DcoumentsUpload";
import { TextArea } from "@progress/kendo-react-inputs";
import { useUploadForm } from "components/fileUploadHook";
import MattersCombobox from "../Postings/MattersComboBox";
import Modalposting from "../Postings/PostingModal";
import { onFileUpload } from "Services/fileDataService";
import { clearFilesDispPayment } from "actions/files.action";
import MatterAccount from "../MatterLedger/MatterAccount";
import CONSTANT from "CONSTANT/Matter/ComboxType";
import useMediaQuery from "hooks/useMediaQuery";
import {
  clearMatterDisbRecords,
  clearMatterTimeRecords,
  getMatterDisbRecords,
  getMatterTimeRecords,
} from "actions/bills.action";
import { createposting } from "actions/postings.action";
import MattersTimeRecords from "./BillMatterPopupGrid";
import TimeGrid from "./BillTimeRecords";
import styles from "./Bills.module.scss";
import { Separator } from "assets/separator";
import DisbGrid from "./BillDisbursementGrid";
import MattersDisbRecords from "./BillDisbPopupGrid";
import BillProfitCostGrid from "./BillProfitCostGrid";
import BillFixedHeadersGrid from "./BillFixedFeePopup";
import { clearActiveFeeItems } from "actions/fixedFeeHeader.action";
import { getMattersBalances } from "actions/matters.action";
import SearchComboBox from "components/common/SearchComboBox";
import MatterDetails from "pages/MatterLedger/MatterDetails";
import { CustomDateInput } from "components/common/CustomDateInput";
import CalenderCustomToggleButton from "components/common/CalenderCustomToogleButton";
import { TransactionPeriod } from "components/common/TransactionPeriod";
import LoadingScreen from "components/common/Loading";
import { getMatterDefaults } from "api";
import { toast } from "react-toastify";

const Bills = (props) => {
  const matches = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();
  const date = new Date();
  const [details, setDetails] = useState("");
  const [matterName, setMatterName] = useState("");
  const [reference, setReference] = useState("*Bill No*");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [matterDefaults, setMatterDefaults] = useState(null);
  const [rate, setRate] = useState("");
  const [initialData, setInitialData] = useState();
  const [totalAmount, setTotalAmount] = useState({
    totalAmount: 0,
    totalVat: 0,
  });
  const [nominalVatValue, setNominalVatValue] = useState({
    nominalRef: "",
    vatCode: "",
    feeEarnerRef: "",
  });
  const [showForm, setshowForm] = React.useState(false);
  const [showDisbForm, setshowDisbForm] = React.useState(false);
  const [showModal, setshowModal] = React.useState(false);
  const [dateselector, setdateselector] = useState(date);
  const [matterRef, setmatterref] = React.useState(null);
  const [resetGrd, setResetGrid] = useState(false);
  const [resetmatter, setresetmatter] = useState(false);
  const [modalvalue, setmodalvalue] = useState(false);
  const [billTimeData, setBillTimeData] = useState([]);
  const [feeEarner, setFeeEarner] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [billDisbData, setBillDisbData] = useState([]);
  const [totalValues, setTotalValues] = useState({
    totalAmount: 0,
    totalVat: 0,
  });
  const [shoWProfitGrid, setShowProfitGrid] = useState(false);
  const [showTemplatesGrid, setShowTemplatesGrid] = useState(false);
  const [matterTimeRecords, setMatterTimeRecords] = useState([]);
  const [matterDisbRecords, setMatterDisbRecords] = useState([]);
  const [matterBalanceClear, setMatterBalanceClear] = useState(false);
  const [error, setError] = useState(undefined);
  const [feeearnerref, setfeeearnerref] = useState();
  const [feeearner, setfeeearnerData] = React.useState();
  const feeearnerdata = useSelector((state) => {
    return state.postingsdocs.feeearner.data;
  });
  const filesCurrentData = useSelector(
    (state) => state.filesDownload.billFiles
  );
  useEffect(() => {
    setTimeout(() => {
      setfeeearnerData(feeearnerdata.slice());
    }, 1000);
  }, [feeearnerdata]);
  useEffect(() => {
    calculateTotalValues();
  }, [matterDisbRecords, matterTimeRecords]);

  const onChangeFeeEarner = async (event) => {
    try {
      setFeeEarner(event?.value);
    } catch (error) {
      console.error(error);
    }
  };

  const getMatterDefaultsData = async (id) => {
    try {
      const data = await getMatterDefaults(id);
      setMatterDefaults(data?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangematter = (e) => {
    let event = e.value;
    setMatterBalanceClear(false);
    if (event !== null) {
      setLoadingData(true);
      dispatch(getMattersBalances(event.matterId, "Bills"));
      getMatterDefaultsData(event?.matterId);
      setmatterref(event);
      setMatterName(event.matterRef);
      // dispatch(getMatterTimeRecords(event.matterId));
      dispatch(getMatterDisbRecords(event.matterId));
      if (event.feeEarnerRef) {
        setFeeEarner({
          representation: `${event?.feeEarnerRef} - ${event?.feeEarnerName}`,
          userRef: event?.feeEarnerRef,
        });
      }
      setTimeout(() => {
        setLoadingData(false);
      }, 1000);
    } else {
      setmatterref(null);
      setMatterBalanceClear(true);
      dispatch(clearMatterTimeRecords());
      dispatch(clearMatterDisbRecords());
    }
  };
  const onChangedateselect = (event) => {
    setdateselector(event.value);
  };

  const calculateTotalValues = () => {
    let amount = {
      totalAmount: 0,
      totalVat: 0,
    };
    if (matterTimeRecords[0] !== undefined) {
      matterTimeRecords.map((item) => {
        amount.totalAmount += item.value;
        amount.totalVat += item.billVat;
      });
    }
    if (matterDisbRecords[0] !== undefined) {
      matterDisbRecords.map((item) => {
        amount.totalAmount += item.netAmount;
        amount.totalVat += item.vatAmount;
      });
    }
    setTotalValues(amount);
  };
  const handleSubmit = async (values) => {
    let amount = {
      totalAmount: 0,
      totalVat: 0,
    };
    if (matterTimeRecords[0] !== undefined) {
      matterTimeRecords.map((item) => {
        amount.totalAmount += item.value;
        amount.totalVat += item.billVat;
      });
    }
    if (matterDisbRecords[0] !== undefined) {
      matterDisbRecords.map((item) => {
        amount.totalAmount += item.netAmount;
        amount.totalVat += item.vatAmount;
      });
    }
    const data = [
      {
        postingType: "Bill",
        matterID: matterRef.matterId,
        date: dateselector,
        reference: reference,
        details: details,
        netAmount: amount.totalAmount,
        vatAmount: amount.totalVat,
        feeEarnerRef: feeEarner?.userRef,
        transactionPeriod: 202201,
        fcCurrencyCode: "GBP",
      },
    ];
    if (
      data[0].matterID === undefined ||
      data[0].feeEarnerRef === undefined ||
      data[0].details === "" ||
      data[0].units === "" ||
      data[0].reference === "" ||
      data[0].rate === ""
    ) {
      setError(true);
      return;
    }
    if (billTimeData[0] !== undefined) {
      data[0].billTime = [];
      data[0].billProfitCost = [];
      billTimeData.map((item) => {
        if (item.type !== "ProfitCost") {
          item.nominalRef = nominalVatValue.nominalRef.nominalRef;
          item.vatCode = nominalVatValue.vatCode.vatCodeId;
          item.feeEarnerRef = nominalVatValue.feeEarnerRef;
          data[0].billTime.push(item);
        } else if (item.type === "ProfitCost") {
          data[0].billProfitCost.push(item);
        }
      });
    }

    if (billDisbData[0] !== undefined) {
      data[0].billDisb = billDisbData;
    }
    if (filesCurrentData[0] !== undefined) {
      const docsId = [];
      filesCurrentData.map((item) => {
        docsId.push(item.fileID);
      });
      data[0].DocumentFiles = docsId;
    }
    try {
      setIsSubmitting(true);
      const response = await api.createposting(data);
      toast.success(
        `Posted Successfully for ${response?.data?.data[0]?.postingType} - ${response?.data?.data[0]?.transactionNo} `
      );
      setTotalValues({
        totalAmount: 0,
        totalVat: 0,
      });
      setMatterDisbRecords([]);
      setInitialData({ feeEarnerRef: "" });
      reset();
      setMatterBalanceClear(true);
    } catch (error) {
      toast.error("Error occured while posting");
    } finally {
      setIsSubmitting(false);
    }
  };
  const reset = () => {
    setDetails("");
    // setfeeearnerref(null);
    setReference("BillNo");
    setRate("");
    // setresetmatter(!resetmatter);
    setMatterTimeRecords([]);
    setResetGrid(!resetGrd);
    setError(false);
    calculateTotalValues();
    dispatch(clearMatterTimeRecords());
    dispatch(clearFilesDispPayment());
  };

  const { isSuccess, uploadForm, progress } = useUploadForm();

  const [filesData, setFilesData] = React.useState([]);

  const handleUpload = async (e) => {
    let data = onFileUpload(e);
    setFilesData([...filesData, data]);
    const dataChoosen = await uploadForm(data, e.target.files[0]);
    data.fileID = dataChoosen.data.data.fileID;
  };

  const onFileRemove = (fileId) => {
    setFilesData((prev) => prev.filter((file) => file.fileID !== fileId));
  };

  return (
    <div
      className="container-fluid ps-0 pt-0"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="row h-100">
        <div
          className="ms-2 bgForm pt-4 d-flex justify-content-center align-item-center"
          style={{
            width: "350px",
            overflow: "auto",
            maxHeight: "calc(100vh - 130px)",
          }}
        >
          <Form
            ignoreModified={true}
            onSubmit={handleSubmit}
            initialValues={initialData}
            key={JSON.stringify(initialData)}
            render={(formRenderProps) => {
              return (
                <div className="w-100 px-2">
                  <FormElement id="billForm" name="billForm">
                    <fieldset>
                      {formRenderProps.visited &&
                        formRenderProps.errors &&
                        formRenderProps.errors.VALIDATION_SUMMARY && (
                          <div className={"k-messagebox k-messagebox-error"}>
                            {formRenderProps.errors.VALIDATION_SUMMARY}
                          </div>
                        )}

                      <div className="mb-3">
                        <Label className="label">Matter Ref</Label>
                        <div
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <MattersCombobox
                            required={true}
                            onChangematter={onChangematter}
                            // reset={resetmatter}
                            style={{
                              width: "100%",
                            }}
                            // borderRadius={"10px"}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="label">Date</Label>
                        <div
                          style={{
                            borderRadius: "15px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <DatePicker
                            className={`px-2 ${styles["datePicker-bill"]}`}
                            value={dateselector}
                            dateInput={CustomDateInput}
                            toggleButton={CalenderCustomToggleButton}
                            onChange={onChangedateselect}
                            defaultValue={dateselector}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="label">Reference</Label>
                        <Input
                          required={true}
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            paddingRight: "2%",
                          }}
                          width={200}
                          value={reference}
                          placeholder="Reference"
                          onChange={(e) => {
                            setReference(e.target.value);
                          }}
                          className="px-3"
                        />
                      </div>

                      <div className="mb-3">
                        <SearchComboBox
                          name="feeEarnerRef"
                          cbType={CONSTANT.FE}
                          placeholder="Fee earner"
                          customCombo={true}
                          width="auto"
                          value={feeEarner}
                          boxShadow={true}
                          className="px-2"
                          onChangeFunction={onChangeFeeEarner}
                          // borderRadius="10px"
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="label">Details</Label>

                        <TextArea
                          required={true}
                          style={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
                            height: "3.2rem",
                            padding: "2%",
                          }}
                          rows={10}
                          placeholder="Add Details"
                          value={details}
                          onChange={(e) => {
                            setDetails(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col d-flex justify-content-end">
                        <DocumentsUpload
                          progress={progress}
                          filesData={filesData}
                          onFileRemove={onFileRemove}
                          onUpload={handleUpload}
                          type={"Bill"}
                          onError={() => {
                            const newFiles = filesData.splice(
                              filesData.length,
                              1
                            );
                            setFilesData([...newFiles]);
                          }}
                        />
                      </div>
                    </fieldset>
                  </FormElement>
                </div>
              );
            }}
          />
        </div>
        <div className="col ms-0 ps-1 pb-4" style={{ overflowX: "auto" }}>
          <div
            style={{
              overflow: "visible",
              minWidth: "900px",
              whiteSpace: "nowrap",
            }}
          >
            <TransactionPeriod />
            <div className="w-100 mt-3">
              <Modalposting
                value={modalvalue}
                reset={() => {
                  reset();
                }}
                closeModal={() => {
                  setmodalvalue(false);
                }}
                component={"Posting"}
              ></Modalposting>

              <div>
                <div className="ps-3">
                  <div
                    className="row align-items-stretch "
                    style={{ display: !matches ? "none" : "" }}
                  >
                    <div className="col-6">
                      <MatterDetails
                        matter={matterRef}
                        width={"100%"}
                        clear={matterBalanceClear}
                      />
                    </div>
                    <div className="col-6 ">
                      <MatterAccount
                        mt="0px"
                        float="right"
                        component={"Bills"}
                        matterId={matterName}
                        clear={matterBalanceClear}
                      />
                    </div>
                  </div>

                  <div
                    className="mt-4 billing-grid d-flex flex-column align-items-end"
                    style={{ height: "calc(100vh - 350px)" }}
                  >
                    <div>
                      <div className={`${styles["grid-container"]} p-3 me-2`}>
                        <TimeGrid
                          data={matterTimeRecords}
                          reset={resetGrd}
                          openForm={() => {
                            setshowForm(true);
                          }}
                          updateMattersData={(data) => {
                            setMatterTimeRecords(data);
                          }}
                          loading={loadingData}
                          openProfitForm={() => {
                            setShowProfitGrid(true);
                          }}
                          openProfitCostGrid={() => {
                            setShowProfitGrid(true);
                          }}
                          openTemplatesGrid={() => {
                            setShowTemplatesGrid(true);
                          }}
                          changeTotalAmount={(value) => {
                            setTotalAmount(value);
                          }}
                          changeBillTimeData={(item) => {
                            setBillTimeData(item);
                          }}
                          disabled={matterRef === null || loadingData}
                          loa
                        />
                        <DisbGrid
                          data={matterDisbRecords}
                          reset={resetGrd}
                          loading={loadingData}
                          disabled={matterRef === null || loadingData}
                          openForm={() => {
                            setshowDisbForm(true);
                          }}
                          changeBillDisbData={(item) => {
                            setBillDisbData(item);
                          }}
                          changeTotalAmount={(value) => {
                            setTotalAmount(value);
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-end right-align mt-4 me-3">
                        <div
                          style={{ gap: "10px" }}
                          className={`d-flex py-0 justify-content-end ${styles["numeric-container"]}`}
                        >
                          <div
                            className={`ps-0 pt-1.5 ${styles["right-border"]} ${styles["bg-Color"]}`}
                            style={{ paddingTop: "5.5px" }}
                          >
                            <span className="fs-sm fw-semibold">
                              Bill Total
                            </span>
                          </div>
                          <div>
                            <NumericTextBox
                              width={150}
                              style={{
                                textAlign: "right",
                                border: "none",
                                height: "35px",
                              }}
                              value={totalValues.totalAmount}
                              readOnly={true}
                              placeholder="0.00"
                              spinners={false}
                              format="n2"
                            />
                          </div>
                          <div className="mt-1">
                            <Separator color={"#E6E6E6"} />
                          </div>
                          <NumericTextBox
                            width={150}
                            style={{
                              textAlign: "right",
                              border: "none",
                              height: "35px",
                            }}
                            value={totalValues.totalVat}
                            readOnly={true}
                            placeholder="0.00"
                            spinners={false}
                            format="n2"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="buttonstyle me-3 mb-4"
                      style={{
                        minHeight: "100px",
                      }}
                    >
                      <Button className="common-btn bgBtnSecondary" type="">
                        Cancel
                      </Button>
                      <Button
                        className="common-btn"
                        type="submit"
                        disabled={isSubmitting}
                        form="billForm"
                      >
                        Post
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {showDisbForm && (
                <MattersDisbRecords
                  cancelEdit={() => {
                    setshowDisbForm(false);
                  }}
                  matterId={matterRef?.matterId}
                  changeMatterDisbList={(data) => {
                    setMatterDisbRecords(data);
                  }}
                />
              )}
              {showForm && (
                <MattersTimeRecords
                  changeMatterList={(data) => {
                    setMatterTimeRecords([...matterTimeRecords, ...data]);
                  }}
                  addNominalVatInfo={(item) => {
                    setNominalVatValue(item);
                  }}
                  cancelEdit={() => {
                    setshowForm(false);
                  }}
                  matterId={matterRef?.matterId}
                  defaultValues={matterDefaults}
                />
              )}
              {showTemplatesGrid && (
                <BillFixedHeadersGrid
                  changeMatterList={(data) => {
                    setMatterTimeRecords([...matterTimeRecords, ...data]);
                  }}
                  addNominalVatInfo={(item) => {
                    setNominalVatValue(item);
                  }}
                  cancelEdit={() => {
                    setShowTemplatesGrid(false);
                    dispatch(clearActiveFeeItems());
                  }}
                  matterId={matterRef?.matterId}
                  defaultValues={matterDefaults}
                />
              )}

              {shoWProfitGrid && (
                <BillProfitCostGrid
                  profitCostGrid={shoWProfitGrid}
                  defaultValues={matterDefaults}
                  changeMatterList={(data) => {
                    setMatterTimeRecords([...matterTimeRecords, ...data]);
                  }}
                  matterId={matterRef?.matterId}
                  cancelEdit={() => {
                    setShowProfitGrid(false);
                  }}
                />
              )}

              <Modalposting
                value={showModal}
                reset={() => {
                  reset();
                }}
                closeModal={() => {
                  setshowModal(false);
                }}
                component={"Posting"}
              ></Modalposting>
            </div>

            {loadingData && <LoadingScreen />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bills;
