export const nominalData = [
  {
    ProductID: 1,
    CreditAmount: 0.0,
    DebitAmount: 0.0,
    NominalInfo: {
      nominalRef: null,
    },
  },
];

let data = [...nominalData];

export const insertItem = (item) => {
  item.inEdit = false;
  data.push(item);
  return data;
};
export const getItems = () => {
  return data;
};
export const updateItem = (item) => {
  let index = data.findIndex((record) => record.ProductID === item.ProductID);
  data[index] = item;
  return data;
};
export const deleteItem = (item, localData) => {
  let dataLocal = [...localData];
  let index = dataLocal.findIndex(
    (record) => record.ProductID === item.ProductID
  );
  dataLocal.splice(index, 1);
  return dataLocal;
};

export const updateData = (item) => {
  data = [...item];
};
