import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "react-router-dom";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import { Popup } from "@progress/kendo-react-popup";
import styles from "../Casefile.module.scss";
import { MenuList } from "components/CaseFiles/CaseFilesHeader";
/*ICONS ***/
import { BsThreeDots } from "react-icons/bs";
import { LuFileSignature } from "react-icons/lu";
import { MdOutlinePreview } from "react-icons/md";
import { LiaDownloadSolid, LiaEditSolid } from "react-icons/lia";
import { IoDuplicateOutline } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { VscFilePdf } from "react-icons/vsc";
import { RiShareForward2Fill } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";

const MenuCell = ({
  setViewActivity,
  setView,
  showDocumentFile,
  handleDownload,
  getDocumentEditorData,
  handleDuplicate,
  setShowShareDocument,
  setCurrentDoc,
  setDeleteConfirmation,
  setESigning,
  setAddActivity,
  loaded,
  setLoaded,
  ...props
}) => {
  const { activityId, documentId, documentName } = props.dataItem;
  const [popupVisible, setPopupVisible] = useState(false);
  const anchor = useRef();

  const handleMenuClick = () => {
    const isSelected = props.selectedState[activityId];
    if (!isSelected) {
      props.showPaneFile(documentId);
      props.setSelectedState({ [activityId]: true });
      props.setMultiSel(false);
    } else if (Object.keys(props.selectedState).length >= 1) {
      setPopupVisible(true);
    }
  };

  useEffect(() => {
    if (props.selectedState[activityId] && !props.multiSel && loaded) {
      setPopupVisible(true);
    }
  }, [loaded, props.multiSel]);

  useEffect(() => {
    const closePopup = (e) => {
      if (e?.target?.role === "menu") return;
      if (e?.target?.role !== "button") {
        setPopupVisible(false);
      }
      if (!anchor.current) {
        setPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", closePopup);
    return () => {
      document.removeEventListener("mousedown", closePopup);
    };
  }, []);

  return (
    <td
      onMouseLeave={(e) => {
        setPopupVisible(false);
        loaded && setLoaded(false);
      }}
    >
      <Popup
        show={popupVisible}
        anchor={anchor.current}
        popupClass={`${styles["popup-content"]} ${styles["menu-popup"]}`}
        anchorAlign={{ horizontal: "right", vertical: "middle" }}
        popupAlign={{ horizontal: "left", vertical: "middle" }}
        animate={false}
      >
        <div className="mb-3" style={{ width: "80%", margin: "auto" }}>
          <div className={styles["border-bottom"]}></div>
          <div className="flex flex-column mt-4 justify-start">
            <MenuList
              border={true}
              icon={<MdOutlinePreview role="button" size={22} color="black" />}
              name="View Activity"
              noMargins
              // index={1}
              onClick={() => {
                setAddActivity(true);
                setViewActivity(props.dataItem);
                setView(true);
              }}
            />
            {documentId && documentName && (
              <>
                <MenuList
                  border={true}
                  noMargins
                  icon={
                    <HiOutlineDocumentText
                      role="button"
                      size={22}
                      color="black"
                    />
                  }
                  name="Preview Document"
                  onClick={() => showDocumentFile(documentId)}
                />
                <MenuList
                  noMargins
                  border={true}
                  name="Download"
                  icon={<LiaDownloadSolid role="button" size={22} />}
                  onClick={() => handleDownload(documentId)}
                />
                {/* {Object.keys(selectedState) && ( */}
                {(documentName.includes(".docx") ||
                  documentName.includes(".doc")) && (
                  <MenuList
                    noMargins
                    border={true}
                    name="Edit"
                    icon={<LiaEditSolid role="button" size={22} />}
                    onClick={() => getDocumentEditorData(documentId)}
                  />
                )}
                <MenuList
                  noMargins
                  border={true}
                  name="Duplicate"
                  icon={<IoDuplicateOutline role="button" size={22} />}
                  onClick={() =>
                    handleDuplicate(props?.matterId, props.dataItem.activityId)
                  }
                />
                {/* )} */}
                {!documentName?.includes(".pdf") && (
                  <MenuList
                    noMargins
                    border={true}
                    name="Download as PDF"
                    icon={<VscFilePdf role="button" size={22} />}
                    onClick={() => handleDownload(documentId, true)}
                  />
                )}

                <MenuList
                  noMargins
                  border={true}
                  icon={<RiShareForward2Fill role="button" size={20} />}
                  name="Share"
                  onClick={() => {
                    setShowShareDocument(true);
                    setCurrentDoc({
                      id: documentId,
                      name: documentName,
                    });
                  }}
                />
              </>
            )}

            {documentId && documentName && (
              <MenuList
                border={true}
                noMargins
                icon={<LuFileSignature role="button" size={22} color="black" />}
                name="E Signing"
                onClick={() => setESigning(activityId)}
              />
            )}
            <MenuList
              noMargins
              border={false}
              name="Delete"
              icon={<RiDeleteBin6Line role="button" size={18} />}
              onClick={() => setDeleteConfirmation(props.dataItem)}
            />
          </div>
        </div>
      </Popup>
      <u
        style={{ cursor: "pointer" }}
        className={
          Object.keys(props.selectedState)[0] === activityId || popupVisible
            ? "d-flex gap-3 "
            : `activity-table-filters gap-3 `
        }
      >
        <div
          ref={anchor}
          onClick={handleMenuClick}
          style={{ cursor: "pointer" }}
        >
          <BsThreeDots color="#323130" size={20} role="button" />
        </div>
      </u>
    </td>
  );
};

export default MenuCell;
